@import "./var.scss";
@import "./mixin.scss";
@import "./keyframes.scss";

// first desktop
.header {
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 11;
    background-color: $white;
}

.wpMenu {
    display: flex;
    justify-content: space-between;
    align-items: stretch;
    padding: 0;
    margin: 0 auto;
    max-width: 1920px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.colLogo,
.colMenu,
.colOptions {
    display: flex;
    align-items: center;
}

.colLogo {
    width: 20%;
    justify-content: center;
}

.colMenu {
    width: 60%;
    justify-content: flex-start;
}

.colOptions {
    width: 20%;
    justify-content: center;
    position: relative;
}

.logo {
    /* width: 11rem; */
    width: 8vw;
    display: inline-block;
    margin: 0 auto;
}

.menu {
    height: 100%;
    display: flex;
    justify-content: flex-start;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    li {
        display: flex;
        align-items: center;
        white-space: nowrap;
        height: 100%;
        a {
            padding: 0.3vw 0.5vw;
            -webkit-border-radius: 8px;
            -moz-border-radius: 8px;
            border-radius: 8px;
            @include toAnim(0.3s);
            &:hover {
                background-color: $primary;
                color: white;
            }
        }
    }
    
}

.menuItem {
    color: $primary;
    font-weight: 400;
    font-size: 1.1vw;
    margin-right: 0.6rem;
    line-height: 100%;
    &:hover {
        color: $secundary;
    }
}

.options {
    list-style: none;
    padding: 0;
    margin: 0;
    height: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    li {
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        height: 100%;
        a,
        button {
            padding: 0.5vw 1vw;
            height: 100%;
            display: flex;
            align-items: center;
            justify-content: center;
            &:hover {
                background-color: $primary;
                color: white;
            }
        }
        ul {
            margin: 0;
            padding: 0;
            position: absolute;
            top: 100%;
            left: 50%;
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
            transform: translateX(-50%);
            background-color: white;
            @include toAnim(0.3s);
            opacity: 0;
            visibility: hidden;
            li {
                width: 100%;
                a {
                    white-space: nowrap;
                    font-size: 1vw;
                    width: 100%;
                    justify-content: flex-start;
                    @include toAnim(0.3s);
                    border-bottom: 1px solid transparent;
                    &:hover{
                        background-color: white;
                        color: $primary;
                        border-bottom: 1px solid $secundary;
                    }
                }
                h6{
                    width: 100%;
                    padding: 0.5vw 1vw;
                }
            }
        }
        &:hover {
            ul {
                opacity: 1;
                visibility: visible;
            }
        }
    }
}

.itemOptions {
    button,
    a {
        color: $primary;
        position: relative;
        background-color: transparent;
        border: 0;
        font-size: 1vw;
        @include toAnim(0.3s);
        img{
            height: 1.1vw;
        }
        &:hover {
            color: $secundary;
            img{
                filter: brightness(0) invert(1);
            }
        }
        
    }
}

// big desktop
@media only screen and (min-width: 1920px) {

    .logo{
        width: 11rem;
    }

    .menuItem {
        font-size: 1.3rem;
    }

    .itemOptions {
        button,
        a {
            font-size: 1.3rem;
            img{
                height: 1.5rem;
            }
        }
    }
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {

}

// big tablet LG
@media (max-width: 1200px) {
    .itemOptions {
        button,
        a {
            font-size: 1.1rem;
            img{
                height: 1.1rem;
            }
        }
    }
    .options{
        li{
            a, button{
                padding: 0.5rem;
            }
            ul{
                left: 0;
                li{
                    h6{
                        padding: 0.5rem;
                    }
                    a{
                        font-size: 1rem;
                    }
                }
            }
        }
    }
}


@media (max-width: 992px) {
    .menuItem {
        font-size: 1rem;
    }
}

// all tablet 
@media (min-width: 769px) and (max-width: 1200px) {
    
}

// tablet MD
@media (max-width: 768px) {
    
   

    .logo {
        width: 5rem;
        margin: 0;
    }
    
    .menuItem {
        font-size: 1rem;
    }

    .itemOptions {
        button,
        a {
            font-size: 1.3rem;
            img{
                height: 1.3rem;
            }
        }
    }

}

// tablet SM

@media (max-width: 767px) {
    .colLogo {
        width: 50%;
        justify-content: flex-start;
        padding: 0.5rem;
    }
    
    .colMenu {
        width: 0%;
    }
    
    .colOptions {
        width: 50%;
        padding: 0.5rem;
        justify-content: flex-end;
    }
}


// only mobile

@media (max-width: 576px) {

    .menuMobile{
        width: 100%;
        height: 100%;
        position: fixed;
        bottom: 0;
        left: 0;
        visibility: hidden;
        overflow: hidden;
    }

    .visible{
        visibility: visible;
    }

    .backGroundMenu{
        width: 0%;
        height: 0%;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-border-radius: 50%;
        -moz-border-radius: 50%;
        border-radius: 50%;
        z-index: -1;
        opacity: 0.4;
        @include toAnim(0.5s);
    }

    .backgroundShow{
        width: 1000px;
        height: 1000px;
        opacity: 0.95;
    }

    .wpMobile{
        opacity: 0;
        transform: translateY(3rem);
        @include toAnim(0.5s);
    }

    .wpShow{ 
        opacity: 1;
        transform: translateY(0);
    }

    .logoMenu{
        -webkit-animation: puff-out-center 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
        animation: puff-out-center 1s cubic-bezier(0.165, 0.840, 0.440, 1.000) both;
    }

    .logoShow{
        -webkit-animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
        animation: puff-in-center 0.7s cubic-bezier(0.470, 0.000, 0.745, 0.715) both;
    }

    .logo {
        width: 5rem;
    }

    .closeMenu {
        background-color: transparent;
        border: 0;
        color: $tertiary;
        font-size: 3rem;
        padding: 0;
        opacity: 0;
        position: relative;
        z-index: 0;
        @include toAnim(0.5s);
    }

    .btnCloseShow{
        opacity: 1;
        -webkit-animation: bounce-in-fwd 1.1s both;
                animation: bounce-in-fwd 1.1s both;
    }

    .showMenu {
        background-color: transparent;
        border: 0;
        color: $primary;
        font-size: 2rem;
        padding: 0;
    }

    .separator{
        color: $primary;
        font-size: 2rem;
        padding: 0 0.5rem;
    }

    .menu {
        display: block;
        li{
            justify-content: center;
            a{
                padding: 0.5rem 0;
            }
        }
    }

    .menuItem {
        color: $tertiary;
        font-size: 1.5rem;
    }
    
}











