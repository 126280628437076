@import './var.scss';
@import './mixin.scss';


.arrow{
    width: 2.5vw;
    height: 2.5vw;
    font-size: 2vw;
    display: inline-block;
    color: white;
    @include toAnim(0.3s);
    border: 0;
    background-color: $primary;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    @include toAnim(0.3s);
    
}

.containerSwiper{
    width: 90%;
}

.mySwiper{
    width: 100%;
    margin: 0;
}

.myDisabledClass{
    opacity: 0.5;
    cursor: not-allowed;
}

.spaceArrow{
    width: 5%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.arrow:hover{
    background-color: $secundary;
}

@media only screen and (min-width: 1920px)  {
    .arrow{
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    
}

@media only screen and (min-width: 1200px) and (max-width: 1366px)  {
 
}

@media (max-width: 1200px) {
    .arrow{
        width: 2rem;
        height: 2rem;
        font-size: 1.5rem;
    }
}

@media (max-width: 576px) {

    .arrow{
        width: 2rem;
        height: 2rem,;
        font-size: 1.5rem;
    }
    
}





