 @-webkit-keyframes bounceInTop {
    0% {
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateY(-65px);
              transform: translateY(-65px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateY(-28px);
              transform: translateY(-28px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes bounceInTop {
    0% {
      -webkit-transform: translateY(-500px);
              transform: translateY(-500px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: translateY(-65px);
              transform: translateY(-65px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: translateY(-28px);
              transform: translateY(-28px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    90% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: translateY(-8px);
              transform: translateY(-8px);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: translateY(0);
              transform: translateY(0);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  

@-webkit-keyframes bounce-in-fwd {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: scale(0.84);
              transform: scale(0.84);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    89% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  @keyframes bounce-in-fwd {
    0% {
      -webkit-transform: scale(0);
              transform: scale(0);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
      opacity: 0;
    }
    38% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
      opacity: 1;
    }
    55% {
      -webkit-transform: scale(0.7);
              transform: scale(0.7);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    72% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    81% {
      -webkit-transform: scale(0.84);
              transform: scale(0.84);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    89% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
    95% {
      -webkit-transform: scale(0.95);
              transform: scale(0.95);
      -webkit-animation-timing-function: ease-in;
              animation-timing-function: ease-in;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-animation-timing-function: ease-out;
              animation-timing-function: ease-out;
    }
  }
  
@-webkit-keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  @keyframes puff-in-center {
    0% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
  }
  

@-webkit-keyframes puff-out-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
  }
  @keyframes puff-out-center {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
      -webkit-filter: blur(0px);
              filter: blur(0px);
      opacity: 1;
    }
    100% {
      -webkit-transform: scale(2);
              transform: scale(2);
      -webkit-filter: blur(4px);
              filter: blur(4px);
      opacity: 0;
    }
  }
  



  /* ----------------------------------------------
 * Generated by Animista on 2023-5-11 3:19:2
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-in-blurred-top {
        0% {
          -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
                  transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          -webkit-filter: blur(40px);
                  filter: blur(40px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                  transform: translateY(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 50%;
                  transform-origin: 50% 50%;
          -webkit-filter: blur(0);
                  filter: blur(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-blurred-top {
        0% {
          -webkit-transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
                  transform: translateY(-1000px) scaleY(2.5) scaleX(0.2);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          -webkit-filter: blur(40px);
                  filter: blur(40px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                  transform: translateY(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 50%;
                  transform-origin: 50% 50%;
          -webkit-filter: blur(0);
                  filter: blur(0);
          opacity: 1;
        }
      }
      

/* ----------------------------------------------
 * Generated by Animista on 2023-5-11 3:21:23
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation rotate-out-2-cw
 * ----------------------------------------
 */
 @-webkit-keyframes rotate-out-2-cw {
        0% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          opacity: 0;
        }
      }
      @keyframes rotate-out-2-cw {
        0% {
          -webkit-transform: rotate(0);
                  transform: rotate(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: rotate(45deg);
                  transform: rotate(45deg);
          opacity: 0;
        }
      }

      /* ----------------------------------------------
 * Generated by Animista on 2023-5-11 3:29:48
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-out-blurred-top
 * ----------------------------------------
 */
@-webkit-keyframes slide-out-blurred-top {
        0% {
          -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                  transform: translateY(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          -webkit-filter: blur(0);
                  filter: blur(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
                  transform: translateY(-1000px) scaleY(2) scaleX(0.2);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          -webkit-filter: blur(40px);
                  filter: blur(40px);
          opacity: 0;
        }
      }
      @keyframes slide-out-blurred-top {
        0% {
          -webkit-transform: translateY(0) scaleY(1) scaleX(1);
                  transform: translateY(0) scaleY(1) scaleX(1);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          -webkit-filter: blur(0);
                  filter: blur(0);
          opacity: 1;
        }
        100% {
          -webkit-transform: translateY(-1000px) scaleY(2) scaleX(0.2);
                  transform: translateY(-1000px) scaleY(2) scaleX(0.2);
          -webkit-transform-origin: 50% 0%;
                  transform-origin: 50% 0%;
          -webkit-filter: blur(40px);
                  filter: blur(40px);
          opacity: 0;
        }
      }


/* ----------------------------------------------
 * Generated by Animista on 2023-5-18 22:16:12
 * Licensed under FreeBSD License.
 * See http://animista.net/license for more info. 
 * w: http://animista.net, t: @cssanimista
 * ---------------------------------------------- */

/**
 * ----------------------------------------
 * animation slide-in-top
 * ----------------------------------------
 */
 @-webkit-keyframes slide-in-top {
        0% {
          -webkit-transform: translateY(-1000px);
                  transform: translateY(-1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      @keyframes slide-in-top {
        0% {
          -webkit-transform: translateY(-1000px);
                  transform: translateY(-1000px);
          opacity: 0;
        }
        100% {
          -webkit-transform: translateY(0);
                  transform: translateY(0);
          opacity: 1;
        }
      }
      