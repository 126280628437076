@import './var.scss';
@import './mixin.scss';

.pagination{
    display: flex;
    justify-content: center;
    align-items: center;
    list-style: none;
    padding: 0;
    margin: 0;
    
}

.btn, .arrow{
    background-color: transparent;
    border: 0;
    color: $specialColorB;
    font-size: 1vw;
    line-height: 100%;
}

.active{
    color: #000;
    font-weight: bold;
}

.arrow{
    width: 2vw;
    height: 2vw;
    background-color: $specialColorB;
    color: $white;
}


@media only screen and (min-width: 1920px)  {
    .btn, .arrow{
        font-size: 1rem;
    }
    .arrow{
        width: 1.5rem;
        height: 1.5rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    
}

@media only screen and (min-width: 1200px) and (max-width: 1366px)  {
    .btn, .arrow{
        font-size: 1.3vw;
    }
}

@media (max-width: 1200px) {
    .btn, .arrow{
        font-size: 1.2rem;
    }
    .arrow{
        width: 2rem;
        height: 2rem;
    }
}

@media (max-width: 576px) {
    .btn, .arrow{
        font-size: 1rem;
    }
    .arrow{
        width: 2rem;
        height: 2rem;
    }
}





