@import "./var.scss";
@import "./mixin.scss";

.contentAux {
  padding: 2vw;
  background-color: white;
}

.containerItem {
  padding: 1.5vw 0;
}

.hMap {
    height: 37vw;
}

// big desktop
@media only screen and (min-width: 1920px) {
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
   .hMap {
        height: 33rem;
    }
}

// big tablet LG

@media (max-width: 1200px) {
}

@media (max-width: 1200px) and (orientation: portrait) {
  .txt {
    padding: 3rem;
  }
  .containerItem {
    padding: 3rem;
  }
}

@media (max-width: 1200px) and (orientation: landscape) {
  .txt {
    padding: 0 3rem;
  }
  .containerItem {
    padding: 3rem;
  }
}

// mobile devices
@media (max-width: 1024px) and (orientation: landscape) {
}

@media (max-width: 768px) {
  .txt {
    padding: 0 2rem;
  }
  .containerItem {
    padding: 2rem 1rem;
  }
  .hMap {
        height: 20rem;
    }
}

// only mobile

@media (max-width: 576px) {
  .hMap {
        height: 16rem;
    }
}
