@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.pedido{
    h3{
        font-size: 1rem;
    }
    p{
        font-size: 1rem;
    }
}

@media (max-width: 576px) {

}

@media (min-width: 768px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    .pedido{
        h3{
            font-size: 0.9vw !important;
        }
        h4{
            font-size: 0.9vw !important;
        }
        p{
            font-size: 0.9vw;
        }

    }  
}

@media (min-width: 1920px) {

}