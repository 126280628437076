@import "./var.scss";

.iconTrash {
    font-size: 1.7vw;
}

.listTimelineEstadoPedido {
    list-style: none;
    padding: 0;
    margin-left: 1rem;
    width: 1px;
    background-color: $secundary;
    li {
        min-width: 400px;
        max-width: 100%;
        display: flex;
        align-items: center;
        margin-bottom: 3.5rem;
        &:last-child {
            margin-bottom: 0;
        }
        &::before {
            content: "";
            display: inline-block;
            width: 1.4rem;
            height: 1.4rem;
            border: 1px solid $secundary;
            background-color: $secundary;
            box-shadow: inset 0 0 0 5px $white;
            translate: -50% 0;
            border-radius: 50%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            -ms-border-radius: 50%;
            -o-border-radius: 50%;
        }
    }
}


.colPedido{
    width: 50%;
}

.itemPerfil{
    padding: 1.2vw;
    margin-bottom: 2vw;
}

.smallTitle{
    width: 25%;
    display: inline-block;
}

@media(min-width: 1920px) {
    
}


@media(max-width: 1200px) {
    .iconTrash { 
        font-size: 1.5rem;
    }
}


@media (max-width: 576px) {
    .listTimelineEstadoPedido{
        li{
            min-width: 250px;
        }
    }
    .colPedido{
        width: 100%;
    }
    .smallTitle{
        width: 50%;
    }
    .itemPerfil{
        padding: 1rem;
        margin-bottom: 1rem;
    }
}