@import "./var.scss";
@import "./mixin.scss";

.toAnim{
    @include toAnim(0.3s);
}

.itemFade{
    opacity: 0;
    @include toAnim(1s);
}

.fadeIn{
    opacity: 1;
}
.fadeOut{
    opacity: 0;
}

.itemTranslateY{
    opacity: 0;
    transform: translateY(50px);
    @include toAnim(0.5s);
}

.itemUp{
    opacity: 1;
    transform: translateY(0px);
}

/* .itemDown{
    opacity: 1;
    transform: translateY(-100px);
} */

.absoluteLoader{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 10;
}

.contentLoader{
    width: 50%;
    margin: 0 auto;
    min-height: 25rem;
    display: flex;
    justify-content: center;
    align-content: center;
}

.minHeight{
    min-height: 90vh;
}

.minHeightScale{
    min-height: 56vw;
}

.paddingFixHeader{
    padding-top: 6.7vw;
    /* min-height: 50vw; */
}

.boxShadow{
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
}

.boxShadowB{
    -webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
    -moz-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
    box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
}

.shadowError {
    background-color: rgba(0, 0, 0, 0.4);
}

.backgroundSize{
    background-size: cover;
    background-position: center ;
    background-repeat: no-repeat;
    background-color: $gray-1;
}

.radiusCircle{
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
}

.radius50{
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
}

.radius15{
    -webkit-border-radius: 15px;
    -moz-border-radius: 15px;
    border-radius: 15px;
}

.input, .textarea{
    @extend .boxShadow;
    @extend .radius50;
    border: 1px solid $primary;
}

.input{
    background-position: calc(100% - 15px) 50%;
    &:focus{
        /* outline: none; */
        border-width: 2px;
    }
}


.textarea{
    @extend .radius15;
}

.btnW{
    position: fixed;
    bottom: 2vw;
    right: 2vw;
    z-index: 10;
    @include toAnim(0.3s);
    border: 0;
    color: white;
    padding: 0.5rem 1rem;
    background-color: $whatsapp-b;
    -webkit-border-radius: 7px;
    -moz-border-radius: 7px;
    border-radius: 7px;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 1.2vw;
    span{
        font-size: 1vw;
        margin-right: 0.5rem;
        color: white;
    }
    Icon{
        color: white;
    }
}

.absoluteCenter{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

//check gris pequeño
.containerCheckS, .containerCheckSC {
    display: block;
    display: flex;
    align-items: center;
    height: auto;
    position: relative;
    padding-left: 1.3rem;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 0.8vw;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    small {
        font-size: 0.8vw;
        p{
            margin-bottom: 0;
        }
    }
}
/* Hide the browser's default checkbox */
.containerCheckS input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmarkS, .checkmarkSB, .checkmarkSC {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 15px;
    width: 15px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid white;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkS:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.containerCheckS input:checked ~ .checkmarkS img, .containerCheckSC input:checked ~ .checkmarkSC img {
    visibility: visible;
    opacity: 1;
    height: 20px;
}

/* Style the checkmark/indicator */

.containerCheckS img, .containerCheckSC img {
    height: 0px;
    visibility: hidden;
    opacity: 1;
    @include toAnim(0.3s);
}

.checkmarkSB{
    width: 20px;
    height: 20px;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    font-size: inherit;
}

.checkmarkSB::before{
    content: '';
    width: 0%;
    height: 0%;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: white;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    opacity: 0;
    @include toAnim(0.3s);
}

/* Show the checkmark when checked */
.containerCheckSB input:checked ~ .checkmarkSB::before {
    visibility: visible;
    opacity: 1;
    width: 60%;
    height: 60%;
}

.d_f_100 {
    width: 100%;
    height: 100vh;
}

.minHeightModal{
    min-height: 40vw;
}

.logosMarcas{
    height: 2.2vw;
    margin: 0.5vw 1.5vw;
}

@media (min-width: 1920px) {
    .paddingFixHeader{
        padding-top: 8rem;
    }
    .minHeightScale,minHeightModal{
        min-height: 1080px;
        max-height: 1080px;
    }
}


@media (min-width: 1200px) {
    .container95{
        max-width: 95% !important;
    }
    .container70{
        max-width: 70% !important;
    }
    .logosMarcas{
        height: 2rem;
    }
}

@media (max-width: 1600px) {
    .paddingFixHeader{
        padding-top: 6.6vw;
    }
}

@media (max-width: 1420px) {
    .paddingFixHeader{
        padding-top: 7.4vw;
    }
}

@media (max-width: 1200px) {
    .containerCheckS {
        font-size: 0.9rem;
        small {
            font-size: 0.9rem;
        }
    }

    .btnW{
        bottom: 1rem;
        right: 1rem;
        font-size: 1rem;
        span{
            font-size: 0.8rem;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
  
}


@media (max-width: 576px) {
    .paddingFixHeader{
        padding-top: 100px;
    }

    .logosMarcas{
        height: 3rem;
        margin: 0.5rem 1rem;
    }
}