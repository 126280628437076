@import "./scss/var.scss";
@import "./scss/fonts.scss";

@mixin toAnim($tempo) {
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

//style

body {
    font-size: 16px;
    font-family: "Montserrat" !important;
}

.main {
    max-width: 1920px;
    margin: 0 auto;
}

::-webkit-scrollbar-thumb {
    border-radius: 0.45rem;
    background-color: $primary;
}

::-webkit-scrollbar {
    border-radius: 0.45;
    width: 0.35rem;
    background-color: rgba(165, 208, 128, 1);
}

a {
    text-decoration: none !important;
    cursor: pointer;
}

.deco_l_b {
    width: 9%;
    left: 0;
    bottom: 0;
    position: absolute;
}

.deco_r_t {
    width: 9%;
    right: 0;
    top: 0;
    position: absolute;
}

input {
    outline: none;
}

.source-sans {
    font-family: "Source Sans Pro";
}

.cursor-pointer {
    cursor: pointer;
}


.modalPreview{
    .modal-dialog{
        max-width: 1024px;
    }
}

.checkbox {
  position: relative;
  //top: rem(-6);
  
  margin: 0 1rem 0 0 ;
  
  cursor: pointer;
  
  &:before {
    // @include transition(transform .4s cubic-bezier(.45,1.8,.5,.75));
    //  @include transform(rotate(-45deg) scale(0, 0));
    
    content: "";
    
    position: absolute;
    // left: rem(3);
    //  top: rem(2);
    z-index: 1;
  
    width: auto;
    height: auto;
    
     border-top-style: none;
     border-right-style: none;
  }
  
  &:after {
    content: "";
    position: absolute;
    top: 0.1rem;
    left: -0.1rem;
    width: 1rem;
    height: 1rem;
    background: #29af5f00;
    border: 2px solid white;
    cursor: pointer;
  }
}

.text-underline {
    text-decoration: underline !important;
}

.text-justify {
    text-align: justify;
}

.note-publi {
    background-color: #f5f4f2;
    font-family: "Source Sans Pro";

    a {
        color: black;
        text-decoration: none;

        &:hover {
            text-decoration: none;
        }
    }
}

.tooltip {
    .tooltip-inner {
        background-color: $primary !important;
        max-width: 18rem !important;
        text-align: left;
    }
    &.bs-tooltip-top {
        .tooltip-arrow {
            &::before {
                border-top-color: $primary !important;
            }
        }
    }
}

.grid-stars {
    display: flex;
    align-items: center;
    .singular-star {
        margin-right: 0.25rem;
        font-size: 1.5rem;
        &::after {
            content: "";
        }
        &.active {
            &::after {
                color: $primary;
            }
        }
    }
}

.special-item {
    position: relative;

    &::before {
        content: "";
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
        background-color: rgba($primary, 0.4);
        opacity: 0;
        @include toAnim(0.5s);
    }

    .txt {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        z-index: 2;

        h2 {
            text-align: center;
            color: white;
            text-transform: uppercase;
        }
    }

    &:hover {
        &::before {
            opacity: 1;
        }
    }
}

select {
    background-image: url("../public/img/flecha.png");
    background-repeat: no-repeat;
    background-position: right center;
    -webkit-appearance: none;
    -moz-appearance: none;
    -o-appearance: none;
    appearance: none;
    outline: none;
    &.select-gris {
        background-image: url("../public/img/flecha_blanca.png");
        background-origin: content-box;
    }
}
select::-ms-expand {
    display: none;
}

form {
    input[type="text"],
    input[type="email"],
    input[type="number"],
    input[type="tel"],
    input[type="password"],
    input[type="date"],
    input[type="time"],
    select,
    label,
    textarea {
        width: 100%;
        outline: none;
    }

    input,
    select {
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $gray-1;
        background-color: transparent;
        &.radio-button {
            border: 1px solid white;
        }
    }

    input,
    textarea {
        &:focus {
            outline: none !important;
            box-shadow: 0 0 0px $primary !important;
        }
    }

    textarea {
        min-height: 7rem;
        border-radius: 0.8rem;
        border: 1px solid $gray-1;
    }

    label {
        font-size: 1.1rem;
    }

    input:focus,
    select:focus,
    textarea:focus,
    button:focus {
        outline: none;
    }

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus,
    input:-webkit-autofill:active {
        -webkit-box-shadow: 0 0 0 30px white inset !important;
    }

    .add-file {
        position: relative;
        input {
            position: absolute;
            top: 0;
            left: 0;
            height: 100%;
            opacity: 0;
            cursor: pointer;
        }
    }

    .talla {
        position: relative;
        display: inline-block;

        label {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        input[type="radio"].css-checkbox {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            top: 0;
            left: 0;
            /* margin:-1px;  */
            padding: 0;
            border: 0;
        }

        input[type="radio"].css-checkbox + label.css-label {
            /* padding-left:20px; */
            width: 2.5rem;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid black;
            font-size: 1rem;
            vertical-align: middle;
            cursor: pointer;
        }

        input[type="radio"].css-checkbox:checked + label.css-label {
            background-color: black;
            color: white;
        }
    }

    .precio {
        position: relative;
        display: inline-block;

        label {
            -webkit-touch-callout: none;
            -webkit-user-select: none;
            -khtml-user-select: none;
            -moz-user-select: none;
            -ms-user-select: none;
            user-select: none;
        }

        input[type="radio"].css-checkbox {
            position: absolute;
            overflow: hidden;
            clip: rect(0 0 0 0);
            height: 1px;
            width: 1px;
            top: 0;
            left: 0;
            /* margin:-1px;  */
            padding: 0;
            border: 0;
        }

        input[type="radio"].css-checkbox + label.css-label {
            /* padding-left:20px; */
            width: auto;
            height: 2.5rem;
            display: flex;
            justify-content: center;
            align-items: center;
            border: 1px solid black;
            font-size: 1rem;
            vertical-align: middle;
            cursor: pointer;
        }

        input[type="radio"].css-checkbox:checked + label.css-label {
            background-color: black;
            color: white;
        }
    }

    .color-radio {
        display: inline-block;
        position: relative;
        width: 2.5rem;
        height: 2.5rem;
        border-radius: 50%;

        input {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: 1;
            opacity: 0;
            cursor: pointer;
        }

        input:checked + .check {
            /* background-color: red; */
            border: 2px solid black !important;
        }

        .check {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            border: 0px solid #b9d3d7;
        }
    }
    .border-w {
        input:checked + .check {
            border: 2px solid rgb(136, 136, 136) !important;
        }
    }

    .counter {
        font-size: 1rem;

        div {
            div {
                padding: 0.2rem !important;
            }
        }
    }

    /* The container */
    .containerCheck {
        display: block;
        position: relative;
        padding-left: 35px;
        margin-bottom: 12px;
        cursor: pointer;
        font-size: 22px;
        -webkit-user-select: none;
        -moz-user-select: none;
        -ms-user-select: none;
        user-select: none;
    }

    /* Hide the browser's default checkbox */
    .containerCheck input {
        position: absolute;
        opacity: 0;
        cursor: pointer;
        height: 0;
        width: 0;
    }

    /* Create a custom checkbox */
    .containerCheck {
        position: absolute;
        top: 0;
        left: 0;
        height: 25px;
        width: 25px;
        background-color: #eee;
    }

    /* On mouse-over, add a grey background color */
    .containerCheck:hover input ~ .checkmark {
        background-color: white;
    }

    // .containerCheck input:checked~.checkmark {
    //     background-color: $pink-1;
    // }

    /* Create the checkmark/indicator (hidden when not checked) */
    .containerCheck:after {
        content: "";
        position: absolute;
        display: none;
    }

    /* Show the checkmark when checked */
    .containerCheck input:checked ~ .checkmark:after {
        display: block;
    }

    /* Style the checkmark/indicator */
    .containerCheck .checkmark:after {
        left: 9px;
        top: 5px;
        width: 5px;
        height: 10px;
        border: solid white;
        border-width: 0 3px 3px 0;
        -webkit-transform: rotate(45deg);
        -ms-transform: rotate(45deg);
        transform: rotate(45deg);
    }

    .link-reset-password {
        font-size: 0.8rem;
    }

    

    /*  .container-check-s .checkmark-s:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  } */
}

.dropdown-menu {
    --bs-dropdown-link-active-bg: none !important;
}

.migas {
    font-size: 1rem;
    li {
        list-style: none;
        .miga {
            text-transform: capitalize;
            &::after {
                content: url("../public/img/icon-miga.png");
                color: $primary;
                font-weight: bold;
                display: inline-block;
                height: 0.5rem;
                margin: 0rem 0.5rem;
            }
            &:hover {
                color: #e60064;
            }
        }
        &:last-child {
            font-weight: 600;
            a:hover {
                color: $primary;
            }
        }
    }
}

.bolsa-checkout {
    .products-table {
        max-height: 14rem;
        overflow-y: auto;
    }
}

.item-collapse {
    a {
        text-decoration: none;

        span {
            color: $primary;
            @include toAnim(0.3s);
        }

        &:hover {
            span {
                color: $gray-1;
            }
        }
    }
}

.NavPoliticas {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
        a {
            color: $gray-1;
            text-decoration: none;
            font-weight: 400;
            font-size: 1.3rem;
            @include toAnim(0.3s);

            &:hover,
            &.active {
                color: $primary;
                font-weight: 700;
            }
        }
    }
}


.others-products {
    .container {
        max-width: 90%;
    }
}

.swiper-relacionados {
    .swiper {
        width: 100%;
        overflow: hidden;
        margin: 0;
    }
}

.productosBienestar {
    position: relative;
    .deco {
        width: 20%;
        position: absolute;
        left: 0;
        bottom: 0;
        z-index: 0;
    }
    .swiper {
        width: 100%;
        margin: 0;
        overflow: hidden;
        .swiper-slide {
            text-align: center;
            img{
                width: 60%;
            }
        }
    }
}

.SliderHome{
    .swiper{
        width: 100%;
        margin: 0;
    }
}

.swiper {
    width: 90%;
    margin: 0 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    /* overflow: inherit; */
    .swiper-button-prev,
    .swiper-button-next {
        width: 5%;
        z-index: 1;
        &::after {
            content: "";
        }
        &.swiper-button-lock {
            display: block;
        }
        &.swiper-button-disabled {
            opacity: 1;
        }
    }
    .swiper-button-prev,
    .swiper-button-next {
        width: 2rem;
        height: 2rem;
        background-size: contain;
        background-position: center;
        background-repeat: no-repeat;
    }
    .swiper-button-prev {
        background-image: url("../public/img/SVG/swiper-button-prev.svg");
        left: inherit;
        right: 100%;
    }
    .swiper-button-next {
        background-image: url("../public/img/SVG/swiper-button-next.svg");
        right: inherit;
        left: 100%;
    }
    .swiper-pagination {
        border-radius: 50px;
        -webkit-border-radius: 50px;
        -moz-border-radius: 50px;
        -ms-border-radius: 50px;
        -o-border-radius: 50px;
        .swiper-pagination-bullet {
            margin: 0 7px;
            opacity: 1;
            background-color: #CFBBF4;
            width: 15px;
            height: 15px;
            &.swiper-pagination-bullet-active {
                background-color: #A57DEE;
            }
        }
    }
}

/// old styles

.modal-compra {
    .modal-content {
        .modal-body {
            .icon-close {
                position: absolute;
                right: 0.5rem;
                top: 0.5rem;
            }
        }
    }
}

.modal {
    &.sm-modal {
        .modal-content {
            border-radius: 0.6rem !important;
        }
    }
    .modal-content {
        overflow: hidden;
        border-radius: 0.7rem;
        .input-border {
            border-radius: 0.5rem;
        }
    }
    .close-modal {
        position: absolute;
        top: 1rem;
        right: 1rem;
        font-size: 1.6rem;
        z-index: 10;
    }
    .close-icon {
        position: absolute;
        top: 0.3rem;
        right: 0.3rem;
    }
}

.img-blur {
    cursor: pointer;

    img {
        @include toAnim(0.5s);
    }

    &:hover {
        img {
            filter: blur(4px);
            -webkit-filter: blur(4px);
        }
    }
}

.head-feed-intagram {
    a {
        color: black;
        text-decoration: none;
    }
}

.lightwidget-widget {
    width: 100%;
}

.mapUbicacion {
    width: 100%;
    height: 400px;

    & > div {
        width: 100%;
        height: 100%;
    }
}

.content-user {
    background-position: center;
    background-size: cover;
}

.user {
    background: linear-gradient(180deg, #ebd0b4 0%, #fbb4a6 100%);

    .simple-link,
    a {
        text-decoration: none;
        color: black !important;
    }

    h2 {
        font-size: 1rem !important;
    }

    .container-check-s {
        .checkmark-s {
            border: 1px solid black !important;
        }

        small {
            color: black;
        }
    }
}

//Add by Oscar please reorder
@import url("./lib/animate.min.css");

.error-msg {
    text-align: center;
    background: white;
    margin-bottom: 13pt;
    color: $primary;
    font-size: 0.75rem;
    font-style: italic;
}

.err_login {
    text-align: start;
    background: transparent;
    /* margin-bottom: 13pt; */
    color: #FF5F5F;
    font-size: 0.75rem;
    padding: 0 15px;
}

.bg_degradado_dark {
    background: rgba(0, 0, 0, 0.6);
}

.exclamation {
    color: $alert-danger;
    position: absolute;
    // top: 56%;
    right: auto;
    margin: 1.1rem 0 0 -1.8rem;
    transform: translateY(-50%);
    -webkit-transform: translateY(-50%);
    -moz-transform: translateY(-50%);
    -ms-transform: translateY(-50%);
    -o-transform: translateY(-50%);
}

.abs-info {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 8;
    text-align: center;
    width: 100%;
    padding: 3pt;
    background: #ffffff61;
    font-size: 10pt;
}

.succesfull-msg {
    padding: 10pt;
    text-align: center;
    background: white;
    margin-bottom: 13pt;
    color: green;
}

.text-center-c {
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
}

.pointer {
    cursor: pointer;
}

.loader-box {
    margin: 10vh 0;
    text-align: center;

    img {
        width: 100pt;
    }
}

.chip {
    position: relative;
    background: #dbdada;
    padding: 4pt;
    border-radius: 10pt;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: 2pt;

    a {
        display: contents;
        color: inherit;
        text-decoration: none;
    }
}

header {
    .search-box {
        .input-search {
            border: none;
            outline: none;
            height: 100%;
            width: 100%;
            z-index: 1;
            border-radius: 0.5rem;
        }
        .icon-search {
            z-index: 3;
            position: absolute;
            right: 0;
        }
    }

    .options {
        ul {
            li {
                a {
                    position: relative;
                    .name-user {
                        position: absolute;
                        top: 100%;
                        left: 100%;
                        transform: translateX(-50%);
                        font-size: 0.7rem;
                        min-width: 150px;
                    }
                    .badge {
                        font-size: 10pt;
                        margin: 0 0.3rem;
                        display: flex;
                        align-items: center;
                        justify-content: center;
                    }
                }
            }
        }
    }
}

.animationIconCart {
    animation: animationIconCart 1s infinite;
}

.upper {
    text-transform: uppercase;
}

/* .disabled {
    pointer-events: none;
    filter: grayscale(100%);
    background: #e0e0e0;
    opacity: 0.3;
} */

.buttons-methods {
    .btn-method {
        background: #000000;
        color: white;
        padding: 5pt 10pt;
        margin: 5pt 0;
        width: 225pt;
        display: flex;
        align-items: center;
        cursor: pointer;
        gap: 5pt;
        font-size: 0.75rem;
        &:hover {
            background: #3a3a3a;
            color: white;
        }
    }
}
.contacto {
    .box-contacto {
        border-radius: 0.8rem;
    }
}
.banner-contacto {
    padding-top: 10rem;
    padding-bottom: 7rem;
}
.ficha-arjeta-hover {
    cursor: pointer;
    &:hover {
        box-shadow: 0 0 5pt #000000a6;
    }
}
.icon-ok {
    position: absolute;
    right: 8px;
    top: 8px;
    color: #5b8d76;
}
.select-only-read {
    appearance: none;
    border: 0;
    color: black;
}

.button-input-float {
    position: absolute;
    right: 0;
    top: 0;
    height: 100%;
}

// .product-page {
//     padding-top: 10rem;
// }

.container-load-search {
    width: 80%;
    padding-right: 0.5rem;
}

@keyframes animationIconCart {
    0% {
        transform: scale(1);
    }

    50% {
        transform: scale(1.2);
    }

    100% {
        transform: scale(1);
    }
}

.main {
    &.no-padding {
        padding: 0;
    }
}

.gif-loader {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: rgba($white, 0.6);
    // background-color: rgba($black, .9);
    z-index: 3000;
    .loader-contenido {
        text-align: center;
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        -webkit-transform: translate(-50%, -50%);
        -moz-transform: translate(-50%, -50%);
        -ms-transform: translate(-50%, -50%);
        -o-transform: translate(-50%, -50%);
        color: $white;
        .puntos {
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            transform: rotate(calc(30deg * var(--i)));
            -webkit-transform: rotate(calc(30deg * var(--i)));
            -moz-transform: rotate(calc(30deg * var(--i)));
            -ms-transform: rotate(calc(30deg * var(--i)));
            -o-transform: rotate(calc(30deg * var(--i)));
            &::before {
                content: "";
                position: absolute;
                top: 0;
                left: 50%;
                width: 8px;
                height: 16px;
                background-color: $primary;
                border-radius: 50px;
                -webkit-border-radius: 50px;
                -moz-border-radius: 50px;
                -ms-border-radius: 50px;
                -o-border-radius: 50px;
                transform: scale(0);
                -webkit-transform: scale(0);
                -moz-transform: scale(0);
                -ms-transform: scale(0);
                -o-transform: scale(0);
                animation: animarPuntosLoader 2s infinite;
                -webkit-animation: animarPuntosLoader 2s infinite;
                animation-delay: calc(0.1s * var(--i));
            }
        }
        .content-loader {
            width: 100px;
            height: 100px;
        }
        .text-loader {
            position: absolute;
            bottom: -60px;
            left: 50%;
            translate: -50% 0;
            font-size: 1.5rem !important;
        }
    }
}
@keyframes animarPuntosLoader {
    0% {
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }
    10% {
        transform: scale(1.2);
        -webkit-transform: scale(1.2);
        -moz-transform: scale(1.2);
        -ms-transform: scale(1.2);
        -o-transform: scale(1.2);
    }
    80%,
    100% {
        transform: scale(0);
        -webkit-transform: scale(0);
        -moz-transform: scale(0);
        -ms-transform: scale(0);
        -o-transform: scale(0);
    }
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

input[type="number"] {
    -moz-appearance: textfield;
}

.text-especial {
    font-size: 0.75rem;
}

input,
select {
    min-height: 20px;
}

.inputdate::-webkit-calendar-picker-indicator {
    display: none;
}

.inputdate[type="date"]::-webkit-input-placeholder {
    visibility: hidden !important;
}

.modal-content {
    border: 0 !important;
}

.list-style-none {
    list-style: none;
}

.video-banner {
    position: relative;
    .video {
        width: 100%;
        height: 25rem;
    }
    .video-banner-link {
        position: absolute;
        bottom: 40px;
        left: 50%;
        translate: -50% 0;
    }
}

.bg-options-repeat {
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.price-normal {
    text-decoration: line-through;
}

.section-skew {
    transform: skewY(-2.6deg);
    -webkit-transform: skewY(-2.6deg);
    -moz-transform: skewY(-2.6deg);
    -ms-transform: skewY(-2.6deg);
    -o-transform: skewY(-2.6deg);
    & > * {
        transform: skewY(2.6deg);
        -webkit-transform: skewY(2.6deg);
        -moz-transform: skewY(2.6deg);
        -ms-transform: skewY(2.6deg);
        -o-transform: skewY(2.6deg);
    }
}

section.reviews {
    .progress {
        height: 0.8rem;
        background-color: $secundary !important;
        .progress-bar {
            background-color: #e60064;
        }
    }
    .overall-score {
        font-size: 3.75rem;
        color: #7a7a7a;
        font-weight: 600;
    }
    textarea {
        border: 1px solid #e60064;
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
    }
}

.nav-pills {
    .nav-link {
        border: 2px solid $primary !important;
        color: $primary;
        background-color: $secundary !important;
        font-weight: 600;
        border-radius: 8px !important;
        -webkit-border-radius: 8px !important;
        -moz-border-radius: 8px !important;
        -ms-border-radius: 8px !important;
        -o-border-radius: 8px !important;
        &.active {
            background-color: $primary !important;
        }
        &:hover {
            color: $primary;
        }
    }
}

.search-box-shop {
    input {
        font-size: 1.2rem;
        outline: none;
        border-top: none;
        border-left: none;
        border-right: none;
        border-bottom: 1px solid $gray-1;
    }
}

.points-sale-page {
    .nav-pills {
        .nav-item {
            flex: 0 0 calc(50% - 5px);
            .nav-link {
                width: 100%;
                padding: 1rem;
                font-size: 1.8rem;
            }
        }
    }
    .tab-pane {
        .featured-part {
            background-color: $secundary;
            padding: 1rem;
            border-radius: 16px;
            -webkit-border-radius: 16px;
            -moz-border-radius: 16px;
            -ms-border-radius: 16px;
            -o-border-radius: 16px;
        }
    }
}

.section-features-service {
    .descripcion-features {
        p {
            font-size: 1rem !important;
            margin-bottom: 0;
        }
    }
}

.social-media-sharing {
    .icon {
        width: 1.9rem;
    }
}

.no-margin {
    p {
        margin-bottom: 0;
    }
}


.data-customer-service-fixed {
    position: fixed;
    left: 25px;
    z-index: 3;
    border: 3px solid $primary;
    background-color: $white;
    border-radius: 16px;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    -ms-border-radius: 16px;
    -o-border-radius: 16px;
}

.info {
    width: 100%;
}

.tooltip {
    background-color: #e60064 !important;
    font-size: 1rem;
    color: white !important;
}
.toggle-arrow {
    .icon-arrow {
        transform: rotate(180deg);
    }
}

.img-modal {
    object-fit: cover;
    border-top-left-radius: 1.5rem;
    border-bottom-left-radius: 1.5rem;
}

.modal-compra {
    .modal-dialog {
        max-width: 45rem !important;
        .icon-compra {
            font-size: 1.5rem;
        }
        .btn-g {
            width: 150px;
        }
    }
}

.alerta-eliminar {
    .modal-content {
        position: relative;
        border-radius: 0.6rem !important;
        .close {
            position: absolute;
            top: 0.5rem;
            right: 0.5rem;
        }
    }
}

.vertical-top {
    vertical-align: top;
}


