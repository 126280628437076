@import './mixin.scss';


.listProductsCheckout{
    overflow-y: scroll;
    height: 25vw;
}


// big desktop
@media only screen and (min-width: 1920px) {
    
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {

}

//Laptop
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    
}



//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
   
}

// big tablet LG
@media (max-width: 1200px) {
    .listProductsCheckout{
        height: 25rem;
    }
}

// tablet MD
@media (max-width: 768px) {
   
}

// only mobile

@media (max-width: 576px) {
    
}
