@import "./var.scss";
@import "./mixin.scss";

.recipeItem{
    position: relative;
    @include toAnim(.3s);
    a{
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        z-index: 1;
    }
    &:hover{
        .contentImg{
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;    
        }
    }
}

.contentImg{
    @include toAnim(.3s);
    overflow: hidden;
}

.imgRecipeInternal {
    width: 100%;
    height: 40vw;
    z-index: 1;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.rowPosition {
    position: relative;
    .boxRecipeInternal {
        position: absolute;
        right: 0;
        bottom: 20px;
        width: 40%;
    }
}

.video {
    width: 100%;
    height: 35vw;
}

//check gris pequeño
.containerCheckS {
    display: block;
    display: flex;
    align-items: center;
    height: auto;
    position: relative;
    padding-left: 25px;
    margin-bottom: 1rem;
    cursor: pointer;
    font-size: 1vw;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    small {
        font-size: 1vw;
        /* text-decoration: line-through; */
        p{
            margin-bottom: 0;
            font-size: 1vw;
        }
    }
}
/* Hide the browser's default checkbox */
.containerCheckS input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.checkmarkS {
    position: absolute;
    top: 4px;
    //transform: translateY(-50%);
    left: 0;
    height: 20px;
    width: 20px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 2px solid #EBAC5B;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    @include toAnim(0.3s);
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkS:after {
    content: "";
    width: 0px;
    height: 0px;
    position: absolute;
    top: 50%;
    left: 50%;
    opacity: 0;
    transform: translate(-50%,-50%);
    background-color: $primary;
    -webkit-border-radius: 50%;
    -moz-border-radius: 50%;
    border-radius: 50%;
    display: none;
    @include toAnim(0.3s);
}

/* Show the checkmark when checked */
.containerCheckS input:checked ~ .checkmarkS img{
    visibility: visible;
    opacity: 1;
    height: 20px;
}

.containerCheckS input:checked ~ .checkmarkS{
    border-color: $primary;
}

.containerCheckS input:checked ~ small{
    text-decoration: line-through;
}

.containerCheckS input:checked ~ .checkmarkS:after {
    display: block;
    width: 12px;
    height: 12px;
    opacity: 1;
}

/* Style the checkmark/indicator */


.loaderIngredient{
    width: 3rem;
}

.containerCheckS img {
    height: 0px;
    visibility: hidden;
    opacity: 1;
    @include toAnim(0.3s);
}

@media (max-width: 1200px) {
    .rowPosition {
        position: unset;
        .boxRecipeInternal {
            position: unset;
            right: unset;
            bottom: unset;
            width: unset;
        }
    }

    .video {
        width: 100%;
        height: 47vw;
    }
}

@media (min-width: 768px) and (max-width: 1200px) {
    .imgRecipeInternal {
        width: 100%;
        height: 50vw;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }
    .containerCheckS{
        font-size: 0.8rem;
        small{
            font-size: 0.8rem;
        }
    }
    .checkmarkS{
        top: 0px;
    }
}

@media (max-width: 767px) {
    .imgRecipeInternal {
        width: 100%;
        height: 100%;
        img {
            width: 100%;
            height: 100%;
            object-fit: cover;
        }
    }

    .containerCheckS{
        font-size: 1rem;
        small{
            font-size: 1rem;
        }
    }
    .checkmarkS{
        top: 2px;
    }
}







