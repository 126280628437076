@import "./var.scss";
@import "./mixin.scss";

.span {
    font-size: 0.9vw;
    line-height: normal;
}

.btnMap {
    border: 2.5px solid $secundary;
    background: white;
    -webkit-border-radius: 16px;
    -moz-border-radius: 16px;
    border-radius: 16px;
    padding: 1vw;
}

.bigIcon {
    font-size: 1.3vw;
}

// big desktop
@media only screen and (min-width: 1920px) {
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .span {
        font-size: 1rem;
    }
    .bigIcon {
        font-size: 1rem;
    }
}

// big tablet LG
@media (max-width: 1200px) {
}

// @media (min-width: 769px) and (max-width: 1000px) {
    
// }

// tablet MD
@media (max-width: 768px) {
    .span {
        font-size: 1rem;
    }
    .bigIcon {
        font-size: 1.7rem;
    }
}

// only mobile

@media (max-width: 576px) {
    .span {
        font-size: 0.9rem;
    }
    .bigIcon {
        font-size: 1.5rem;
    }
}
