@use "mixin.scss";

@value: colors: "../scss/var.scss";
@value secundary, secundaryA, secundaryB, secundaryC, gray1 from colors;

.h1 {
    /* font-size: 40px; */
    font-size: 2vw;
}

.h2 {
    /* font-size: 30px; */
    font-size: 1.5vw;
}

.h3 {
    /* font-size: 22px; */
    font-size: 1.1vw;
}

.h4 {
    font-size: 0.9vw;
}

.h4Special {
    /* font-size: 80px; */
    font-size: 4.6vw;
}

.p {
    /* font-size: 18px; */
    font-size: 1vw;
    small {
        font-size: 0.9vw;
    }
}

.input{
    font-size: 1vw;
}

.contentAdmin {
    h1, h4 {
        @extend .h1;
    }
    h2, h5 {
        @extend .h2;
        font-weight: bold;
        margin-bottom: 2rem;
    }
    h3, h6 {
        @extend .h3;
    }
    p, li {
        @extend .p;
    }
    // normalmente no se deben traer todos estos estilos en el modulo de texto, 
    //solo deberian ser font-size en este modulo, pero por el proyecto al tener que cargar listas desde el admin fue necesario
    ul {
        list-style: none;
        padding: 0;
        li {
            margin-bottom: 1.5rem;
            &::before {
                content: "";
                display: inline-block;
                width: 1.375rem;
                height: 1.375rem;
                vertical-align: middle;
                border: 2px solid #EBAC5B;
                margin-right: .5rem;
                border-radius: 50%;
                -webkit-border-radius: 50%;
                -moz-border-radius: 50%;
                -ms-border-radius: 50%;
                -o-border-radius: 50%;
            }
        }
    }
}

.paragraph {
    font-size: 1vw;
    p {
        font-size: 1vw;
    }
}

.specialText {
    p {
        font-size: 0.8vw;
    }
}

// big desktop
@media only screen and (min-width: 1920px) {
    .h1 {
        /* font-size: 40px; */
        font-size: 2.5rem;
    }

    .h2 {
        /* font-size: 30px; */
        font-size: 1.8rem;
    }

    .h3 {
        /* font-size: 22px; */
        font-size: 1.3rem;
    }

    .h4 {
        font-size: 1.1rem;
    }

    .h4Special {
        /* font-size: 80px;
        font-size: 4.6vw; */
        font-size: 4.7rem;
    }

    .p {
        font-size: 1.1rem;
        small {
            font-size: 1rem;
        }
    }

    .input{
        font-size: 1.1rem;
    }

    .paragraph {
        font-size: 1.1rem;
        p {
            font-size: 1.1rem;
        }
    }
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
}


// big tablet LG
@media (max-width: 1200px) {
    .h1 {
        /* font-size: 40px; */
        /* font-size: 5rem; */
        font-size: 4.2rem;
    }

    .h2 {
        /* font-size: 30px; */
        font-size: 1.6rem;
    }

    .h3 {
        /* font-size: 22px; */
        font-size: 1.2rem;
    }

    .p {
        /* font-size: 18px; */
        font-size: 1rem;
        small {
            /* font-size: 14px; */
            font-size: 0.8rem;
        }
    }

    .special-text {
        font-size: 12px;
    }

}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .h1 {
        font-size: 3.8rem;
    }

    .h2 {
        font-size: 1.3rem;
    }
    .h3 {
        font-size: 1.1rem;
    }
    .p{
        font-size: 0.8rem;
    }
    .special-text {
        font-size: 10px;
    }
}


// tablet MD
@media (max-width: 768px) {
    .h1 {
        font-size: 1.5rem;
    }

    .h2 {
        font-size: 1.2rem;
    }

    .h3 {
        font-size: 1rem;
    }


    .p {
        font-size: 0.8rem;
        small {
            font-size: 0.7rem;
        }
    }

    .input{
        font-size: 0.8rem;
    }

    .paragraph {
        font-size: 0.8rem;
        p {
            font-size: 0.8rem;
        }
    }

    .special-text {
        font-size: 12px;
    }
}

// only mobile

@media (max-width: 576px) {

    .h1 {
        font-size: 2.5rem;
    }

    .h2 {
        font-size: 1.4rem;
    }

    .h3 {
        font-size: 1.2rem;
    }


    .p {
        font-size: 0.8rem;
        small {
            font-size: 0.7rem;
        }
    }

    .input{
        font-size: 0.8rem;
    }

    .paragraph {
        font-size: 0.8rem;
        p {
            font-size: 0.8rem;
        }
    }

    .special-text {
        font-size: 12px;
    }

}
