@import './var.scss';
@import './mixin.scss';
@import './keyframes.scss';

.productFilter{
    z-index: 1001;
    background-color: white;
    &.view{
        top: 50%;
        visibility: visible;
        opacity: 1;
    }
}

.stickFilter{
    position: sticky;
    top: 10vw;
    overflow-y: scroll;
    max-height: 70vh;

    &::-webkit-scrollbar-thumb {
        border-radius: 0.45rem;
        background-color: $primary;
    }
    
    &::-webkit-scrollbar {
        border-radius: 0.45;
        width: 0.35rem;
        background-color: rgba(255, 255, 255, 1);
    }
}

.item-filtro-link{
    white-space: nowrap;
    padding: 0.7rem 1.4rem;
    &:hover{
        color: black;
    }
}

.tagFilter{
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.closeTag{
    cursor: pointer;
    border: 0;
    background-color: $secundary;
    color: white;
    &:hover{
        color: $gray-1;
    }
}

.itemFiltro{
    margin: 1.2vw 0;
}

.arrow{
    font-size: 1.4vw;
    @include toAnim(0.3s);
}

.activeArrow{
    transform: rotate(180deg);
}

.containerCheckS {
    display: block;
    display: flex;
    align-items: center;
    height: auto;
    position: relative;
    padding-left: 1.5vw;
    margin-bottom: 0.9vw;
    cursor: pointer;
    font-size: 1vw;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    small {
        font-size: 0.9vw;
        line-height: 110%;
    }
    &:last-child{
        margin-bottom: 0;
    }
}
/* Hide the browser's default checkbox */
.containerCheckS input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}
/* Create a custom checkbox */
.containerCheckS .checkmarkS {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 0;
    height: 1vw;
    width: 1vw;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid $secundary;
    border-radius: 50%;
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmarkS:after {
    content: "";
    position: absolute;
    visibility: hidden;
    @include toAnim(0.3s);
}

/* Show the checkmark when checked */
.containerCheckS input:checked ~ .checkmarkS:after {
    visibility: visible;
    opacity: 1;
    width: 105%;
    height: 105%;
    background-color: $secundary;
    border-radius: 50%;
}

/* Style the checkmark/indicator */

.containerCheckS img {
    height: 0px;
    visibility: hidden;
    opacity: 1;
    display: none;
    @include toAnim(0.3s);
}


@media (min-width: 1921px) {
    .containerCheckS {
        font-size: 1.2rem;
        margin-bottom: 0.9rem;
        small {
            font-size: 1.2rem;
        }
    }

    .containerCheckS .checkmarkS{
        height: 1.2rem;
        width: 1.2rem;
    }

    .itemFiltro{
        margin: 1.2rem 0;
    }
    
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
   
}

@media (max-width: 1200px) {
    .containerCheckS   {
        font-size: 1rem;
        small {
            font-size: 1rem;
        }
    }

    .containerCheckS .checkmarkS{
        height: 1rem;
        width: 1rem;
    }

    .arrow{
        font-size: 1.3rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {


}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
 

}

@media only screen  and (max-width: 1200px){
    .containerCheckS{
        padding-left: 1.5rem;
    }
    .opcionMobile{
        color: $secundary;
    }
}

@media (max-width: 1200px) and (orientation: portrait) {
    .tagFilter{
        width: auto;
        
    }

    .stickFilter{
        position: fixed;
        z-index: 1000;
        width: 40%;
        background-color: white;
        padding: 1rem;
        top: 30%;
        left: 30%;
        overflow: initial;
        /* transform: translate(-50%, -50%); */
        -webkit-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
        -moz-box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
        box-shadow: 0px 4px 10px 0px rgba(0,0,0,0.2);
        @include toAnim(0.3s);
    }

    .fixedFilter{
        max-height: 60vh;
        overflow-y: scroll;
        padding-right: 10px;
    }

    .showFilter{
        opacity: 1;
        visibility: visible;
        -webkit-animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
	    animation: slide-in-blurred-top 0.6s cubic-bezier(0.230, 1.000, 0.320, 1.000) both;
    }
    .hideFilter{
        opacity: 0;
        visibility: hidden;
        -webkit-animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
	        animation: slide-out-blurred-top 0.45s cubic-bezier(0.755, 0.050, 0.855, 0.060) both;
    }
}



@media (max-width: 576px) {
    .tagFilter{
        width: auto;
    }

    .fixedFilter{
        max-height: 60vh;
        overflow-y: scroll;
        padding-right: 10px;
    }

    .stickFilter{
        width: 90%;
        top: 20%;
        left: 5%;
    }
}