@import './var.scss';
@import './fonts.scss';
@import './mixin.scss';

.footer{
    /* margin-top: 2vw; */
    /* margin-top: 4.5vw; */
    max-width: 1920px;
    margin: 0 auto;
}

.menu{
    width: 50%;
    list-style: none;
    padding: 0;
    margin: 0;
    li{
        a{
            color: $tertiary;
            display: inline-block;
            margin-bottom: 1.3vw;
        }
    }
}

.deco{
    position: absolute;
    bottom: 100%;
    left: 0;
    width: 100%;
}

.logo{
    img{
        height: 8vw;
    }
}

.social{
    a{
        color: white;
        font-size: 1.4vw;
        @include toAnim(0.3s);
        &:hover{
            color: $secundary;
        }
    }
}

.logos{
    height: 2.2vw;
    margin: 0.5vw 1.5vw;
}

.inputMail{
    display: flex;
    align-items: stretch;
    justify-content: center;
    -webkit-border-radius: 20px;
    -moz-border-radius: 20px;
    border-radius: 20px;
    overflow: hidden;
    button{
        border: 2px solid $secundary;
        -webkit-border-radius: 20px;
        -moz-border-radius: 20px;
        border-radius: 20px;
        color: $secundary;
        background-color: white;
        font-size: 0.9vw;
        @include toAnim(0.3s);
    }
    button:hover{
        background-color: $secundary;
        color: white;
    }
    input[type="email"]{
        font-family: 'Montserrat';
        font-size: 0.9vw;
    }
}

.iridian{
    img{
        height: 2.7vw;
    }
}


// big desktop
@media only screen and (min-width: 1920px) {

    .logo{
        img{
            height: 155px;
        }
    }

    .inputMail{
        button{
            font-size: 1rem;
        }
        input[type="email"]{
            font-size: 1rem;
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
   
}

@media (min-width: 1200px) {
    .container{
        max-width: 95%;
    }
    .wpMenu{
        width: 80%;
        margin: 0 auto;
    }
}

@media (max-width: 1200px) {

    .inputMail{
        button{
            font-size: 1rem;
        }
        input[type="email"]{
            font-size: 1rem;
        }
    }

    .social{
        a{
            font-size: 1rem;  
        }
    }
    .logos{
        height: 2rem;
    }
}

@media (max-width: 576px) {

    .footer{
        padding: 2rem 1rem;
    }

    .inputMail{
        button{
            font-size: 1rem;
        }
        input[type="email"]{
            font-size: 1rem;
        }
    }
    .iridian{
        img{
            height: 3rem;
        }
    }
    .social{
        a{
            font-size: 1.5rem;  
        }
    }
    
    .logos{
        margin: 0.5rem 1rem;
    }

    .logo{
        img{
            height: 5rem;
        }
    }
    .deco{
        display: none;
    }
}