@media (min-width: 1921px) {

    input[type='text'],
    input[type='email'],
    input[type='number'],
    input[type='tel'],
    input[type='password'],
    input[type='date'],
    input[type='time'],
    select,
    label,
    textarea {

        font-size: .8rem;
    }

    /* header{
        .logo{
            height: 4.5rem;
        }
        .menu{
            margin: 0 20rem 0 20rem;
        }
        .search-box{
            width: 30%;
            margin-right: 13rem;
        }
        .localization{
            margin: 0 12rem 0 6rem;
        }
    }

    .product-description{
        p{
            font-size: 0.9rem;
        }
    }
    .search-box-shop{
        input{
            font-size: 1.2rem;
        }
    }

    .btn-whatsapp{
        img{
            height: 4rem;
        }
    }
    .NavPoliticas {
    
        li {
            a {
               
                font-size: 1.2rem;
            }
        }
    } */


    .container-check-s{
        small{
            font-size: 0.8rem !important;
        }
    }

}