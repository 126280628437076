@mixin toAnim ($tempo){
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}

.step-by-step{
    background-color: #F5F4F2;
    margin: 0 auto;
    .step{
        .step-num{
            width: 100%;
            position: relative;
            &::before{
                content: '';
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translateY(-50%);
                width: calc(100% + 1rem);
                height: 2px;
                background-color: #B9D3D7;
                z-index: 1;
                @include toAnim(0.3s);
            }
        }
        h5{
            width: 2rem;
            height: 2rem;
            background-color: #B9D3D7;
            color: white;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 0 auto;
            -webkit-border-radius: 50%;
            -moz-border-radius: 50%;
            border-radius: 50%;
            position: relative;
            z-index: 1;
            @include toAnim(0.3s);
        }
        p{
            color: #B9D3D7;
            @include toAnim(0.3s);
        }
        &:nth-child(4){
            .step-num{
                &::before{
                    display: none;
                }
            }
        }
        &.active{
            .step-num{
                &::before{
                    background-color: black;
                }
            }
            h5{
                background-color: black;
            }
            p{
                color: black;
            }
        }
    }
}

@media (max-width: 576px) {

    .step-by-step{
        .step{
            .step-num{
                &::before{
                    display: none;
                }
            }
            display: none;
            &.active{
                display: block;
            }
        }
    }

}

@media (min-width: 768px) {

}


@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .step-by-step {
        .step{
            width: 25%;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape) {
    .step-by-step {
        .step{
            width: 20%;
        }
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    
    .step-by-step{
        h5{
            font-size: 0.9vw;
        }
        p{
            font-size: 0.8vw;
        }
    }

}

@media (min-width: 1200px) {

    .step-by-step{
        width: 45%;
        .step{
            width: 25%;
        }
    }

}

@media (min-width: 1920px) {


}