@import './mixin.scss';

.banner {
    position: relative;
    min-height: 40vh;
    //background-color: white;
    overflow: hidden;
}

.wp {
    width: 100%;
    height: auto;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    z-index: 1;
    h1 {
        /* font-size: 150px; */
        font-size: 7.8vw;
        line-height: 90%;
    }
}

.video{
    width: 100% !important;
    height: auto !important;
    position: absolute;
    top: 50%;
    left: 0;
    transform: translateY(-50%);
    @include toAnim(0.3s);
}

.video {
    opacity: 0;
}

.playing {
    opacity: 1;
}

.btnPlay{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 2;
    border: 0;
    background-color: transparent;
    color: white;
    font-size: 3vw;
}

.container {
    max-width: 95%;
}

// big desktop
@media only screen and (min-width: 1920px) {
    .wp {
        h1 {
            font-size: 9rem;
        }
    }
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {

}

//Laptop
@media only screen and (min-width: 1200px) and (max-width: 1600px) {
    
}



//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
    .banner{
        min-height: auto;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    .banner{
        min-height: 60vh;
    }
}

// big tablet LG
@media (max-width: 1200px) {
    .wp {
        h1 {
            font-size: 6rem;
        }
    }
}

// tablet MD
@media (max-width: 768px) {
    .wp {
        h1 {
            font-size: 5rem;
        }
    }
}

// only mobile

@media (max-width: 576px) {
    .banner{
        min-height: 50vh;
        display: flex;
        picture{
            display: block;
            img{
                height: 100%;
                object-fit: cover;
            }
        }
        .picture{
            display: block;
            img{
                height: 100%;
                object-fit: cover;
            }
        }
    }

    .video{
        width: auto !important;
        height: 100% !important;
    }

    .btnPlay{
        font-size: 5rem;
        display: inline-block;
        line-height: 100%;
        top: 50%;
        transform: translate(-50%, -50%);
    }

    .wp {
        h1 {
            font-size: 3.8rem;
        }
    }
}
