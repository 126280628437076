@media (min-width: 1200px) {    
    form{
        .talla{
            position: relative;
            display: inline-block;
            
            input[type=radio].css-checkbox {
                height:1px; 
                width:1px; 
            }
            
            input[type=radio].css-checkbox + label.css-label {
                width: 2.5rem;
                height: 2.5rem;
                font-size: 1rem;
            }
        }

        .color-radio{
            width: 2.5rem;
            height: 2.5rem;
        }
    }

    .swiper {
        .swiper-button-prev,
        .swiper-button-next {
            width: 3rem;
            height: 3rem;
        }
    }



    .video-banner {
        .video {
            height: 30rem;
        }
    }


    .section-offers,
    .section-our-recommended {
        .swiper {
            position: unset;
            .swiper-button-prev { 
                left: -5rem;
            }
            .swiper-button-next { 
                right: -5rem;
            }
        }

    }
}