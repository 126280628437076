@media (min-width: 576px) {
    header{
      /* .logo{
        height: 3rem;
      } */
      .wp-call-menu{
        margin-top: -1rem;
        .call-menu{
          font-size: 2rem;
        }
      }

      .localization{
        margin-left: .6rem;
        .icon-location{
          height: 1.3rem;
        }
    
      }
      .pago-pse{
        font-size: .75rem !important;
      }
      .search-box{
        width: 17rem;
        height: 2rem;
        margin-left: 0.5rem;
      }
    }

    .buttons-methods {
      .btn-method {
        width: 170pt;
      }
    }
}