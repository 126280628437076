@import "./var.scss";
@import "./mixin.scss";

.searcherWp{
    width: 100%;
    position: fixed;
    top: 0%;
    left: 0;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-50%);
    @include toAnim(0.3s);
}

.show{
    opacity: 1;
    visibility: visible;
    transform: translateY(0%);
}

.searchBoxContainer {  
  /* The container <div> - needed to position the dropdown content */
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 3vw 2vw;
    background-color: white;
    /* display: none; */
    :focus-within {
        .dropdown-content {
            display:block;
        }
    } 
  /* Show the dropdown menu (use JS to add this class to the .dropdown-content container when the user clicks on the dropdown button) */
}

/* Change color of dropdown links on hover */
.dropdownContent a:hover {background-color: #ddd;}

.searchBox{
    width: 60%;
    border-bottom: 1px solid $primary;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5vw;
}

.inputSearch{
    width: 95%;
    color: $primary;
    background-color: transparent;
    border: 0;
    font-size: 1vw;
}

.iconSearch{
    width: 5%;
    color: $primary;
    background-color: transparent;
    border: 0;
    font-size: 1vw;
}


.close{
    background-color: transparent;
    border: 0;
    color: $primary;
    font-size: 1.1vw;
}


/* Dropdown Content (Hidden by Default) */
.dropdownContent {
    display: none;
    position: absolute;
    top: 86%;
    background-color: white;
    min-height: 10em;
    max-height: 10em;
    min-width: 100%;
    max-width: 100%;
    box-shadow: 0px 8px 16px 0px rgba(0,0,0,0.2);
    border-radius: 0 0 5px 5px;
    z-index: 4;
    padding: 1rem;
    overflow-y: scroll;
    overflow-x: hidden;

    &:nth-child(1){
        width: 50%;
    }

    &:nth-child(2){
        width: 50%;
        position: relative;
    }

    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            a{
                text-decoration: none;
                color: black;
                
            }   
            &:hover{
                background-color: none;
            } 
        }
    }
}

/* 
.buscador{
    width: 100%;
    position: fixed;
    top: 0;
    left: 0;
    background-color: white;
    @include toAnim(0.3s);
    display: flex;
    justify-content: center;
    align-items: flex-start;
    z-index: 10;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-2rem);
    scale: .96;
    &.show{
        opacity: 1;
        visibility: visible;
        transform: translateY(0);
        scale: 1;
    }
    .cols{
        padding: 1rem;
        &:nth-child(1){
            width: 50%;
        }
        &:nth-child(2){
            width: 50%;
            position: relative;
        }
    }
    .logo{
        width: 60%;
    }
    form{
        width: 80%;
        display: flex;
        align-items: center;
        padding: 0.5rem;
        overflow: hidden;
        background-color: rgba(#C4C4C4, .2);
        -webkit-border-radius: 15px;
        -moz-border-radius: 15px;
        border-radius: 15px;
        button{
            border: 0;
            background-color: transparent;
            font-size: 1rem;
            display: flex;
        }
        input{
            width: 100%;
            border: 0;
            background-color: transparent;
            font-size: 1rem;
            &::-webkit-input-placeholder {
                color: #C4C4C4;
                font-size: 0.8rem;
            }
            
            &:-ms-input-placeholder {
                color: #C4C4C4;
                font-size: 0.8rem;
            }
            
            &::placeholder {
                color: #C4C4C4;
                font-size: 0.8rem;
            }
        }
    }
    .close{
        position: absolute;
        top: 0.5rem;
        right: 1rem;
        color: black;
        font-size: 1rem;
    }
    h3{
        font-family: 'Gotham';
    }
    ul{
        list-style: none;
        padding: 0;
        margin: 0;
        li{
            a{
                text-transform: uppercase;
                color: black;
                text-decoration: none;
                &:hover{
                    color: #C4C4C4;
                }
            }    
        }
    }
    
} */

@media (min-width: 1920px) {

}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    
}

@media (min-width: 768px) and (max-width: 1200px) {
    .searchBoxContainer{
        padding: 2rem 1rem;
    }

    .inputSearch{
        font-size: 1rem;
    }
    
    .iconSearch{
        font-size: 1rem;
    }
    
    .close{
        font-size: 1.1rem;
    }

}


@media (max-width: 576px) {

    .searchBoxContainer{
        padding: 1.5rem 1rem;
    }

    .close{
        width: 5%;
        font-size: 1.1rem;
    }

    .searchBox{
        width: 95%;
    }

    .inputSearch{
        width: 90%;
        font-size: 1rem;
    }
    
    .iconSearch{
        width: 10%;
        font-size: 1rem;
    }
    
}




