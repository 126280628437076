.blog {
    .singular-entry-blog {
        img {
            border-radius: 1rem;
            -webkit-border-radius: 1rem;
            -moz-border-radius: 1rem;
            -ms-border-radius: 1rem;
            -o-border-radius: 1rem;
        }
    }
    .banner-entry-blog{
        .img-blog{
            border-bottom-left-radius: 5rem;
        }
    }
    .content{
        max-height: 6rem;
        overflow: hidden;
    }
    .swiper{
        .swiper-slide{
            img{
                border-radius: 1.5rem;
            }
        }
    }
}

@media (max-width: 768px){
    .blog{
        .banner-entry-blog{
            .img-blog{
                border-radius: 1rem;
            }
        }
    }
}