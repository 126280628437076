@import "./var.scss";
@import "./mixin.scss";

.cardBase {
  width: 100%;
  // padding: 8vw 3vw;
}

.marginAutoContent {
  margin: auto;
  transform: translate(-50%, -50%);
}

.pctrImg {
  height: 26.4vw;
}

.txt {
  width: 40%;
  padding: 3vw;
}

.img {
  width: 60%;
}

.imgSelecciones {
  //object-fit: none;
  object-fit: cover;
  height: 100%;
}

// big desktop
@media only screen and (min-width: 1920px) {
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
}

// big tablet LG

@media (max-width: 1200px) {
  .txt {
    p {
      font-size: 14px;
    }
  }
}

@media (max-width: 1200px) and (orientation: portrait) {
  .txt,
  .img {
    width: 50%;
  }
}

// mobile devices
@media (max-width: 1024px) and (orientation: landscape) {
  .cardBase {
    text-align: center;
  }
  .txt {
    padding: 2rem 3rem;
  }
}

@media (max-width: 768px) {
  .txt,
  .img {
    width: 100%;
  }
  .cardBase {
    text-align: center;
  }
  .txt {
    padding: 3rem;
  }
}

// only mobile

@media (max-width: 576px) {
}
