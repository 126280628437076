@import "./var.scss";
@import "./mixin.scss";

.pctr {
    width: 100%;
}

.navLink {
    width: 100%;
    height: 100%;
    position: absolute;
    left: 0;
    top: 0;
    z-index: 1;
}

.hInternaBanner {
    height: 24.6vw;
}


.pctrIntrn {
    width: 30vw;
    position: relative;
}

.imgRecipeInternal {
    width: 100%;
    height: 24vw;
    z-index: 1;
    img {
        width: 100%;
        height: 100%;
        object-fit: cover;
    }
}

.item{
    &:hover{
        .contentImg{
            box-shadow: rgba(100, 100, 111, 0.2) 0px 7px 29px 0px;
            -webkit-border-radius: 20px;
            -moz-border-radius: 20px;
            border-radius: 20px;    
        }
    }
}

.contentImg{
    @include toAnim(.3s);
    overflow: hidden;
}


// big desktop
@media only screen and (min-width: 1920px) {
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
}

// big tablet LG

@media (max-width: 1200px) {
}

@media (max-width: 1200px) and (orientation: portrait) {
    .artcl {
        padding: 2rem 4.5rem;
    }
    .int {
        padding: 2rem 2rem 1rem;
    }
    .sec {
        padding: 1rem 2rem;
    }

    .pctrIntrn {
        width: 80%;
        margin: 0 auto;
    }
}

@media (max-width: 1200px) and (orientation: landscape) {
    .artcl {
        padding: 2rem 0.5rem;
    }
    .int {
        padding: 2rem 2rem;
    }
    .sec {
        padding: 1rem 2rem;
    }
}

// mobile devices
@media (max-width: 991px) and (orientation: landscape) {
    .artcl {
        Picture {
            img {
                padding: 2rem 6rem;
            }
        }
    }
}

@media (max-width: 768px) {
    .artcl,
    .int,
    .sec {
        padding: 1rem;
    }
    .pctrIntrn {
        width: 80%;
        margin: 0 auto;
    }
}

// only mobile

@media (max-width: 576px) {
}
