@import "./var.scss";

.sticky {
    position: sticky;
    /* top: 120px; */
    top: 10vw;
}

.fecha {
    color: #B3B3B3;
}

@media(min-width: 1200px) and (max-width: 1920px) {
    /* .sticky{
        top: 10vw;
    } */
}

@media(min-width: 768px) and (max-width: 1200px) {
    .swiper {
        height: 65vw;
    }
}

@media(min-width: 576px) and (max-width: 768px) {
    .swiper {
        height: 60vw;
    }
}

@media(max-width: 576px) {
    .swiper {
        height: 100vw;
    }
}