
@media (max-width: 576px) {
    

    header{
       .logo-pse{
        height: 2.3rem;
       } 
       .wp-call-menu{
        margin-top: -1rem;
            .call-menu{
            font-size: 2rem;
            &:hover, &:active{
                color: white;
            }
           }
       }
       .search-box{
        width: 80%;
        height: 2rem;
        margin: 0 .8rem;
       }
       h4{
        font-size: 1.1rem !important;
       }
       .md-p{
        font-size: .8rem !important;
       }
       .icon-info-banner{
        font-size: 1.3rem;
       }
       .social{
        a{
            font-size: 1.5rem;
            &.youtube{
                font-size: 1.9rem;
            }
        }
       }
    }

    .banner{
        .btn-big{
            font-size: 0.8rem;
            left: 12px;
            bottom: 12px;
            transform: translateX(0%);
        }
        .c-special{
            top: 45%;
        }
    }

    .special-item {
        .txt {
            h2{
                font-size: 1rem !important;
            }
        }
    }

    .note-publi {
        a{
            font-size: 0.9rem;
        }
    }
    
    .link-header{
        p, a{
            font-size: 0.8rem;
        }
    }

    .icono-metodo{
        img{
          height: 1.8rem;
          &.visa{
            height: 1.4rem;
          }
        }
    }

    .lightwidget-widget{
        height: 24rem;
        margin-bottom: -8px;
    }

    form{
        input[type='text'], input[type='email'], input[type='number'], input[type='tel'], input[type='password'], input[type='date'], input[type='time'], select, label, textarea{
            font-size: 0.9rem;
        }
        .talla{
            position: relative;
            display: inline-block;
            
            input[type=radio].css-checkbox {
                height:1px; 
                width:1px; 
            }
            
            input[type=radio].css-checkbox + label.css-label {
                width: 1.5rem;
                height:1.5rem;
                font-size: 0.8rem;
            }
        }

        .color-radio{
            width: 1.5rem;
            height: 1.5rem;
        }
    }
    
    .feedback{
        background-color: #B9D3D7;
        .fondo{
            display: none;
        }
        .content-banner{
            width: 100%;
            position: relative;
            top: inherit;
            left: inherit;
            transform: translate(0%, 0%);
            padding: 2rem;
        }
        .slide-process{
            display: none;
        }
    }

    .pregunstas, .contactanos{
        .content-banner{
            width: 100%;
            position: relative;
            top: inherit;
            left: inherit;
            transform: translate(0%, 0%);
            padding: 1rem;
            h2{
                color: black;
            }
            h3{
                color: black;
            }
        }
    }

    .filtros-activos{
        .chip{
            font-size: 0.8rem;
            padding: 0.2rem 0.3rem;
            line-height: 100%;
        }
        a{
            font-size: 0.8rem;
            padding: 0.3rem;
        }
    }
    

    .item-nueva-coleccion{
        background-color: black;
        h3{
            color: white;
        }
        .btn-g{
            &.bb{
                background-color: white;
                color: black !important;
            }
        }
    }

    .user{
        h2{
            font-size: 1rem !important;
        }
        .simple-link, a{
            font-size: 0.8rem;
        }
    }

    .list-products, .add-other-car{
        .singular-product{
            h4{
                font-size: 1.2rem !important;
            }
            p{
                font-size: 1rem !important;
            }
        }
    }

    .controls-quantity {
        .control {
            .icon {
                width: 0.6rem;
            }
        }
        input {
            font-size: 1rem;
           
        }
    }
    .singular-product{
        padding: 0.5rem;
        .name{
            height: 4.1rem;
        }
        .agotado{
            top: 1rem;
            left: 0;
            font-size: 1rem;
            border-top-right-radius: .8rem;
            border-bottom-right-radius:.8rem;
        }
    }
    .points-sale-page {
        .nav-pills {
            .nav-item {
                .nav-link {
                    font-size: 1rem;
                    padding: 0.5rem 1rem;
                    margin: 1rem 0;
                }
            }
        }
    }

    .contacto{
        .info{
            p{
                line-height: 1.2rem;
            }
        }
    }

}