@import './var.scss';

.navPerfil {
    li{
        margin-bottom: 2.5vw;
        a{
            font-size: 1vw;
            color: black;
        }
        &:nth-last-child(1){
            margin-bottom: 0;
        }

    }
    svg {
        color:  $primary;
    }
}

.active{
    color: $primary;
    font-weight: bold;
    span{
        color: $primary;
    }
}


@media (min-width: 1920px) {
    .navPerfil {
        li{
            margin-bottom: 2.7rem;
            a{
                font-size: 1.1rem;
            }
        }
    }
}


@media (max-width: 1200px) {
 
    .navPerfil {
        li{
            margin-bottom: 1rem;
            a{
                font-size: 0.9rem;
            }
        }
    }
}


@media (max-width: 576px) {

    .navPerfil {
        li{
            a{
                font-size: 1rem;
            }
        }
    }
    
}