@import './var.scss';
@import './mixin.scss';

.product{
    padding: 1rem;
    display: flex;
    justify-content: space-between;
    align-items: center;
    @include toAnim(.3s);
}

.img{
    width: 30%;
    text-align: center;
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    @include toAnim(.3s);
    img{
        max-height: 6vw;
    }
}

.product:hover {
    background-color: $white;
    color: $primary;
}

.txt{
    width: 35%;
}

.trash{
    width: 10%;
    cursor: pointer;
    @include toAnim(.3s);
    font-size: 1.2vw;
    color: $quinary;
    text-align: center;
}

.quantity{
    width: 25%;
    display: flex;
    justify-content: center;
}

@media (max-width: 576px) {
    .img{
        width: 25%;
        img{
            max-height: 5rem;
        }
    }
    .trash{
        width: 8%;
        font-size: 1.2rem;
    }
}

@media (min-width: 768px) {

}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    .img{
        img{
            max-height: 15vw;
        }
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    
}