@import "./var.scss";
.loader {
  width: 100px;
  height: 100px;
  border-radius: 50%;
  border: 3px solid $loader_border;
  border-top-color: transparent;
  animation: spin 1.2s linear infinite;
}

.loader::before,
.loader::after {
  content: "";
  position: absolute;
  border-radius: inherit;
}

@keyframes spin {
  to {
    transform: rotate(360deg);
  }
}

.loader::before {
  width: 100%;
  height: 100%;
  background-color: $loader;
  // background-image: linear-gradient(0deg, #f0c 0%, #80f 100%);
}

.loader::after {
  width: 100%;
  height: 100%;
  top: auto;
  left: auto;
}
