@import './var.scss';
@import './mixin.scss';

.contItem{
    -webkit-border-radius: 30px;
    -moz-border-radius: 30px;
    border-radius: 30px;   
}

.item{
    padding: 1vw;
}

.icon{
    font-size: 1.5vw;
}



// big desktop
@media only screen and (min-width: 1920px) {

}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {

}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
}

// big tablet LG
@media (max-width: 1200px) {
    
}

@media (max-width: 1024px) and (orientation: landscape) {
    
}

// tablet MD
@media (max-width: 768px) {
}

// only mobile

@media (max-width: 576px) {
    .item{
        padding: 1rem;
    }
    .icon{
        font-size: 1.5rem;
    }   
}
