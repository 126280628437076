.error-404-page {
    span {
        display: block;
        width: 100%;
        line-height: 1;
    }
    .title-error {
        font-size: 6.25rem;
    }
    .text-ops {
        font-size: 4rem;
    }
    .page-not-found {
        font-size: 3.5rem;
    }
}

