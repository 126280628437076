@import "./var.scss";
@import "./mixin.scss";

.btn,
.btnSecundary,
.btnDisabled {
    font-size: 0.9vw;
    padding: 0.5vw 3vw;
    font-weight: 600;
    -webkit-border-radius: 50px;
    -moz-border-radius: 50px;
    border-radius: 50px;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.1);
    display: inline-block;
    @include toAnim(0.3s);
    border: 0;
}

.btnSmall{
    font-size: 0.8vw;
    padding: 0.3vw 1.5vw;
}

.btn {
    background-color: $secundary;
    color: white;
    position: relative;
}

.btn .loadingContainer,
.btnSecundary .loadingContainer {
    display: none;
}

.btn:active .loadingContainer,
.btnSecundary:active .loadingContainer {
    display: inline-block;
}


.active .loadingContainer {
    display: inline-block;
}

.dot {
    display: inline-block;
    margin-left: 2px;
    opacity: 1;
    animation: appear 3s infinite;
}

.dot:nth-child(1) {
    animation-delay: 1s;
}

.dot:nth-child(2) {
    animation-delay: 1.5s;
}

.dot:nth-child(3) {
    animation-delay: 2s;
}

@keyframes appear {
    0% {
        opacity: 0;
    }
    33% {
        opacity: 1;
    }
    66% {
        opacity: 1;
    }
    100% {
        opacity: 0;
    }
}

.btn:hover {
    background-color: $secundary;
    color: white;
}

.btn:active {
    background-color: $secundaryB;
    color: white;
}

.btnSecundary {
    background-color: white;
    color: $secundary;
    border: 1px solid $secundary;
}

.btnSecundary:hover {
    background-color: $secundaryC;
    color: white;
    color: $secundary;
}

.btnSecundary:active {
    color: $secundaryB;
    border-color: $secundaryB;
}

.btn:disabled,
.btnDisabled {
    background-color: white !important;
    color: $gray-1;
    pointer-events: none;
}

// big desktop
@media only screen and (min-width: 1920px) {
    .btn,
    .btnSecundary {
        font-size: 1.1rem;
        padding: 0.5rem 3rem;
    }
    .btnSmall{
        font-size: 0.8rem;
        padding: 0.3rem 3rem;
    }
}

@media (max-width: 1600px) {
    .btn,
    .btnSecundary {
        font-size: 1vw;
    }
    .btnSmall{
        font-size: 0.9vw;
    }
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {

}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    .btn,
    .btnSecundary {
        font-size: 0.9rem;
        padding: 0.5rem 3rem;
    }
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    .btn,
    .btnSecundary {
        font-size: 0.9rem;
        padding: 0.5rem 3rem;
    }
}

// tablet MD
@media (max-width: 768px) {

}

// only mobile

@media (max-width: 576px) {
    .btn,
    .btnSecundary {
        font-size: 1rem;
        padding: 0.3rem 3rem;
    }
    .btnSmall{
        font-size: 0.8rem;
        padding: 0.3rem 1.5rem;
    }
}
