@import "./var.scss";

.login-register {
    a:hover {
        color: $pink-1;
    }
    form {
        // background-color: rgba($gray-1, .9);
        border-radius: 16px;
        -webkit-border-radius: 16px;
        -moz-border-radius: 16px;
        -ms-border-radius: 16px;
        -o-border-radius: 16px;
        .danger-alert {
            position: relative;
            input {
                border-bottom: 1px solid #fc4141;
                position: relative;
            }
            .icon-war {
                position: absolute;
                top: 40%;
                right: 3%;
                z-index: 100;
                display: block;
                font-size: 1.2rem;
            }
        }
        .sucess-alert {
            position: relative;
            .icon-war {
                position: absolute;
                top: 40%;
                right: 3%;
                z-index: 100;
                display: block;
                font-size: 1.2rem;
            }
        }
        input,
        select {
            position: relative;
            color: $gray-1;
            border-color: #ffffff98;
            &::placeholder,
            option {
                color: $gray-1;
            }
            background-color: $white !important;
        }
        .icon-war {
            display: none;
        }
        label > .radio-button[type="radio"] + *::before {
            margin-top: 0.3rem;
        }
        .btn-show-pass {
            color: $gray-1;
            position: absolute;
            top: 40%;
            right: 10px;
            transform: translateY(-50%);
            -webkit-transform: translateY(-50%);
            -moz-transform: translateY(-50%);
            -ms-transform: translateY(-50%);
            -o-transform: translateY(-50%);
        }
    }
}
