@import './var.scss';
@import './mixin.scss';

.singularProduct{
    padding: 1rem;
    @include toAnim(.3s);
}

.agotado{
    opacity: 0.4;
}

.singularProduct:hover {
    /* background-color: $white; */
    color: $primary;
}

.wp{
    position: relative;
}

.wp:hover{
    .imgHover{
        opacity: 1;
    }
}

.image {
    transform: scale(1.1);
    -webkit-transform: scale(1.1);
    -moz-transform: scale(1.1);
    -ms-transform: scale(1.1);
    -o-transform: scale(1.1);
    @include toAnim(.3s);
}

.imageProduct{
    position: relative;
    overflow: hidden;
    .image {
        @include toAnim(.3s);
    }
}

.tag{
    width: 25%;
    position: absolute;
    top: 0;
    right: 0;
}

.tagItem{
    position: relative;
    margin-bottom: 0.5vw;
}

.tagWp{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

.tagText{
    font-size: 0.6vw;
    color: white;
}

.discount{
    font-size: 0.9vw;
}

.imgHover{
    position: absolute;
    top: 0;
    left: 0;
    width: auto;
    height: 100%;
    opacity: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: white;
    z-index: 2;
    @include toAnim(.3s);
    img{
        height: 100%;
    }
    a{
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: rgba(0,0,0,.5);
        @include toAnim(.3s);
    }
    button{
        width: 100%;
        position: absolute;
        bottom: 0;
        left: 0;
        border: 0;
        background-color: #FCE266;
        font-size: 1vw;
        @include toAnim(.3s);
    }
    button:hover{
        background-color: $primary;
        color: white;
    }
}

.modalPreview{
    .modal-dialog{
        max-width: 1200px;
    }
}

//interna producto

.containerImages{
    display: flex;
    align-items: stretch;
    position: sticky;
    top: 8vw;
}

.thumbnails{
    height: 30vw;
    overflow-y: hidden;
}

.thumbnailsImg{
    width: 100%;
}

.imgRegular{
    height: 100%;
    width: auto;
}

.contentThumbnails{
    width: 20%;
}

.contentSlider{
    width: 80%;
}

.containterQuanty{
    width: 30%;
}


@media only screen and (min-width: 1920px)  {
    .tagText{
        font-size: 0.8rem;
    }
}

@media only screen and (min-width: 1200px) and (max-width: 1920px)  {
    
}

@media screen and (min-width: 768px) and (max-width: 1200px)  {
    .tag{
        width: 30%;
    }

    .tagItem{
        margin-bottom: 0.5rem;
    }
    
    .tagText{
        font-size: 0.8rem;
    }
    .discount{
        font-size: 1rem;
    }
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    .thumbnails{
        height: 467px;
    }      
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
 .thumbnails{
    height: 435px;
 }   
}

@media (max-width: 1200px) { 
    .imgHover{
        width: 100%;
        height: auto;
        top: inherit;
        bottom: 0;
        background-color: transparent;
        opacity: 1;
        picture, a{
            display: none;
        }
        button{
            font-size: 1.2rem;
        }
    }
}

@media (max-width: 768px) {
    .tagItem{
        margin-bottom: 0.5rem;
    }
    
    .tagText{
        font-size: 0.8rem;
    }
    .discount{
        font-size: 1rem;
    }
}

@media (max-width: 576px) {
    .containerImages{
        flex-direction: column-reverse;
    }
    .contentThumbnails{
        width: 100%;
    }
    .thumbnailsImg{
        width: auto;
        height: 100%;
    }
    .contentSlider{
        width: 100%;
    }
    .containterQuanty{
        width: 50%;
    }
}