.item-categoria{
    position: relative;
    &.selected {
        border: 5pt solid black;
    }
    &:hover {
        border: 5pt solid rgb(197, 197, 197);
    }
    h3{
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        text-shadow: 0 2pt 5pt black;
    }
}


@media (max-width: 576px) {
    .item-categoria {
        h3{
            font-size: 0.9rem !important;
        }
    }
}