@media (max-width: 991px){
 
}

@media (min-width: 992px) {
    header{
      height: auto;
      .logo-pse{
        width: 2.4rem;
      }
      .pago-pse{
        font-size: .85rem !important;
      }

    }
   
}

@media (max-width: 992px) {

    
}

@media (max-width: 1024px){
  

}