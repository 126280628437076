@import "./var.scss";
@import "./mixin.scss";

.quickCar{
    width: 30%;
    height: 100%;
    position: fixed;
    top: 0;
    right: 1rem;
    background-color: white;
    opacity: 0;
    visibility: hidden;
    transform: translateY(-2rem);
    z-index: 10;
    @include toAnim(0.3s);
}

.close{
    font-size: 1.2vw;
}

.title{
    font-size: 1vw;
}

.itemCart{
    border-bottom: 1px solid #EBAC5B;
}

.resume{
    font-size: 0.9vw;
    max-width: 90%;
}

.img{
    width: 20%;
}

.txt{
    width: 80%;
}

.smallControl{
    width: 50%;
}

.scaleComponent{
    transform: scale(0.8);
    transform-origin: left;
}

.price{
    font-size: 1vw;
    white-space: nowrap;
}

.showQuickCar{
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    box-shadow: 0 0 25pt #00000047;
}

.headCart{
    height: 10%;
}

.contentCart{
    height: 65%;
    padding-right: 0.5rem;
    overflow-y: scroll;
}

.footerCart{
    height: 25%;
}

.btn{
    font-size: 0.8vw;
}

@media (min-width: 1920px){
    
}

@media (min-width: 1200px) and (max-width: 1920px)  {
 
    
}

@media (max-width: 1200px){

    .close{
        font-size: 1.1rem;
    }
    
    .title{
        font-size: 1rem;
    }
    
    .resume{
        font-size: 0.9rem;
    }
    
    .scaleComponent{
        transform: scale(1);
        transform-origin: left;
    }
    
    .price{
        font-size: 1rem;
    }
    
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
    .quickCar{
        width: 50%;
    }    
}

@media (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    .quickCar{
        width: 70%;
    }    
}

@media (max-width: 992px) {
    
}


@media (max-width: 768px) {

}

@media (max-width: 576px) {

    .quickCar{
        width: 90%;
    }

    .close{
        font-size: 1.1rem;
    }
    
    .title{
        font-size: 1rem;
    }
    
    .resume{
        font-size: 0.9rem;
    }
    
    .scaleComponent{
        transform: scale(1);
        transform-origin: left;
    }
    
    .price{
        font-size: 1rem;
    }
    

}









