
//Fuentes rosmi

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-Bold.eot');
  src: url('../../assets/fonts/Montserrat-Bold.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Montserrat-Bold.woff2') format('woff2'),
      url('../../assets/fonts/Montserrat-Bold.woff') format('woff'),
      url('../../assets/fonts/Montserrat-Bold.ttf') format('truetype');
  font-weight: bold;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-Regular.eot');
  src: url('../../assets/fonts/Montserrat-Regular.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Montserrat-Regular.woff2') format('woff2'),
      url('../../assets/fonts/Montserrat-Regular.woff') format('woff'),
      url('../../assets/fonts/Montserrat-Regular.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: 'Montserrat';
  src: url('../../assets/fonts/Montserrat-Italic.eot');
  src: url('../../assets/fonts/Montserrat-Italic.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Montserrat-Italic.woff2') format('woff2'),
      url('../../assets/fonts/Montserrat-Italic.woff') format('woff'),
      url('../../assets/fonts/Montserrat-Italic.ttf') format('truetype');
  font-weight: normal;
  font-style: italic;
  font-display: swap;
}

@font-face {
  font-family: 'Autography';
  src: url('../../assets/fonts/Autography.eot');
  src: url('../../assets/fonts/Autography.eot?#iefix') format('embedded-opentype'),
      url('../../assets/fonts/Autography.woff2') format('woff2'),
      url('../../assets/fonts/Autography.woff') format('woff'),
      url('../../assets/fonts/Autography.ttf') format('truetype');
  font-weight: normal;
  font-style: normal;
  font-display: swap;
}


.autography{
    font-family: 'Autography';
    line-height: 100%;
}

.fw-600{
    font-weight: 600;
}