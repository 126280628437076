@import "./var.scss";
@import "./mixin.scss";

.tagBanner {
  position: absolute;
  left: 0;
  z-index: 1;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  background: rgba(0, 0, 0, 0.4);
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 0px;
}

.bigIco {
  font-size: 1rem;
}

.textarea{
  min-height: 11vw;
}

// big desktop
@media only screen and (min-width: 1920px) {
}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {
}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
}

// big tablet LG

@media (max-width: 1200px) {
  .bigIco {
    font-size: 1rem;
  }

  .textarea{
    min-height: 8rem;
  }
}

@media (max-width: 1200px) and (orientation: portrait) {
  .centerForm {
    position: absolute;
    left: 0;
    right: 0;
    margin: auto;
  }
}

// mobile devices
@media (max-width: 1024px) and (orientation: landscape) {
}

@media (max-width: 768px) {
}

// only mobile

@media (max-width: 576px) {
  .tagBanner{
    flex-wrap: wrap;
    h6{
      width: 100%;
      margin-bottom: 0.5rem;
    }
  }
}
