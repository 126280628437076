@import "./var.scss";
@import "./mixin.scss";
@import "./keyframes.scss";

.section{
    height: 200vh;
    background-color: $primary;
    overflow: hidden;
}

.wpMagic{
    top: 50%;
    transform: translateY(-40%);
    left: 300%;
    height: 65vh;
}

.StickyWp{
    top: 0px;
    left: 0%;
    position: sticky;
}

.wp{
    /* width: 2000px; */
    display: flex;
    justify-content: flex-start;
    background-position: left;
    background-repeat: no-repeat;
    background-size: auto 100%;
    background-attachment: fixed;
    @include toAnim(0.3s);
    /* background-color: aqua; */
}

.headSpecialWp{
    width: 40vw;
    opacity: 0;
    @include toAnim(0.3s);
    h4{
        max-width: 80%;
    }
}

.horizontalTitleView{
    opacity: 1;
    /* -webkit-animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both;
	animation: slide-in-top 0.5s cubic-bezier(0.250, 0.460, 0.450, 0.940) both; */
}

.itemSpecialScroll{
    width: 40vw;
    text-align: center;
    scale: 0.8;
    a{
        opacity: 0;
        transform: translateY(100px);
        position: relative;
    }
}

.contentTitle{
    position: relative;
    width: auto;
    display: inline-block;
    &::before{
        content: '';
        width: 0%;
        height: 90%;
        position: absolute;
        top: 5%;
        right: 0%;
        transform: rotate(-1deg);
        background-color: $specialColorA;
        z-index: 0;
        @include toAnim(0.3s);
    }
}

.itemFocus{
    scale: 1;
    a{
        opacity: 1;
        transform: translateY(0px);
    }
    h4{
        width: auto;
        display: inline-block;
        @include toAnim(0.3s);
        font-weight: bold;
        font-size: 2vw;
        position: relative;
        z-index: 1;
    }
    .contentTitle{
        &::before{
            width: 100%;
        }
    }
}

.itemSpecialImage{
    max-height: 55vh;
}

@media only screen and (max-height: 800px) and (orientation: landscape)  {

    .itemSpecialImage{
        max-height: 55vh;
    }
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: portrait)  {
    
}

@media only screen and (min-width: 768px) and (max-width: 1200px) and (orientation: landscape)  {
  
}

@media (max-width: 576px) {

}