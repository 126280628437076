@import './var.scss';
@import './mixin.scss';

.controls{
    display: flex;
    align-items: stretch;
    justify-content: center;
}

.control {
    border: 1px solid $primary;
    color: $primary;
    font-weight: bold;
    width: 2rem;
    height: 1.7rem;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    background-color: transparent;
    padding: 0;
    font-size: 1vw;
}
.icon {
    width: 0.6rem;
}
.Minus {
    border-top-left-radius: .8rem;
    border-bottom-left-radius: .8rem;
}
.Plus {
    border-top-right-radius: .8rem;
    border-bottom-right-radius: .8rem;
}
.input {
    width: 25% !important;
    height: 1.7rem;
    font-size: 1.25rem !important;
    text-align: center;
    border-top: 1px solid $primary;
    border-bottom: 1px solid $primary;
    color: $primary;
    border-left: 0;
    border-right: 0;
}

// big desktop
@media only screen and (min-width: 1920px) {

}

// only desktop XL
@media only screen and (min-width: 1200px) and (max-width: 1920px) {

}

//only tablet
@media only screen and (min-width: 768px) and (max-width: 1200px) {
}

// big tablet LG
@media (max-width: 1200px) {
    
}

@media (max-width: 1024px) and (orientation: landscape) {
    
}

// tablet MD
@media (max-width: 768px) {
}

// only mobile

@media (max-width: 576px) {
    .control {
        width: 1.5rem;
        height: 1.7rem;
        font-size: 1rem;
    }
    .icon {
        width: 0.4rem;
    }
    .input {
        width: 50% !important;
        height: 1.7rem;
        font-size: 0.8rem !important;
    }    
}
