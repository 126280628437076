
$primary: #5B9035;
$secundary: #A57DEE;
$tertiary: #F6F0DA;
$quaternary: #3480D9;

$specialColorA: #EBAC5B;
$specialColorB: #DB5A59;
$specialColorC: #B1D2F4;

$alert-danger: #FF0000;
$alert-sucess: #12B13E;

$gray-1: #646464;
$gray-2: #F6F6F6;



$secundaryA: #C1A5F4;
$secundaryB: #7F5CBF;
$secundaryC: #F9F5FF;

$quinary: #EBAC5B;
$quinaryA: #FFF5C8;
$quinaryB: #F6F0DA;
$quinaryC: #F7E4CA;


$pink-1: #FF739E;
$pink-2: #FC1479;
$pink-3: #C90057;
$pink-4: #B43676;
$pink-5: rgba(#E60064, .1);


$gray-3: #D9D9D9;
$white: #FFF;
$black: #000000;
$green-wpp: #00B52C;
$whatsapp: #25D366;
$whatsapp-b: #128C7E;
$blue: #2682BE;

$loader: #CFBBF4;
$loader_border: #7B19C8;

.primary-color {
    color: $primary;
}

.secundary-color {
    color: $secundary;
}

.tertiary-color {
    color: $tertiary;
}

.quaternary-color {
    color: $quaternary;
}

.quinary-color {
    color: $quinary;
}

.specialColorB-color {
    color: $specialColorB;
}

.alert-danger {
    color: $alert-danger;
}

.alert-sucess {
    color: $alert-sucess;
}

.white-color {
    color: $white;
}
.black-color {
    color: $black;
}
.pink-1-color {
    color: $pink-1;
}
.pink-2-color {
    color: $pink-2;
}
.pink-3-color {
    color: $pink-3;
}
.pink-4-color {
    color: $pink-4;
}

.gray-1-color {
    color: $gray-1;
}
.gray-2-color {
    color: $gray-2;
}
.gray-3-color {
    color: $gray-3;
}
.green-wp-color{
    color: $green-wpp;
}
.blue-color{
    color: $blue;
}
.hr-color{
    color:  #B43676;
}
.hr-color-1{
    color: $primary;

}
.specialColorC-color {
    color: $specialColorC;
}

.loader_color {
    color: $loader;
}

// background
.primary-bg-color {
    background-color: $primary;
}

.secundary-bg-color {
    background-color: $secundary;
}

.tertiary-bg-color {
    background-color: $tertiary;
}

.quaternary-bg-color {
    background-color: $quaternary;
}

.quinary-bg-color {
    background-color: $quinary;
}

.quinaryA-bg-color {
    background-color: $quinaryA;
}

.quinaryB-bg-color {
    background-color: $quinaryB;
}

.quinaryC-bg-color {
    background-color: $quinaryC;
}

.specialA-bg-color {
    background-color: $specialColorA;
}

.specialB-bg-color{
    background-color: $specialColorB;
}

.specialC-bg-color{
    background-color: $specialColorC;
}

.alert-danger-bg-color {
    background-color: $alert-danger;
}

.alert-sucess-bg-color {
    background-color: $alert-sucess;
}
.pink-1-bg-color {
    background-color: $pink-1;
}
.pink-2-bg-color {
    background-color: $pink-2;
}
.pink-3-bg-color {
    background-color: $pink-3;
}
.pink-4-bg-color {
    background-color: $pink-4;
}
.pink-5-bg-color{
    background-color: rgba(#E60064, .1);
}

.gray-1-bg-color {
    background-color: $gray-1;
}
.gray-2-bg-color {
    background-color: $gray-2;
}
.gray-3-bg-color {
    background-color: $gray-3;
}
.white-bg-color{
    background-color: $white;
}
.loader_bg_color {
    background-color: $loader;
}

.whatsapp-bg-color {
    background-color: $whatsapp;
}

.primary-border-color {
    border: 1px solid $primary !important;
}

.secundary-border-color {
    border: 1px solid $secundary !important;
}

.tertiary-border-color {
    border: 1px solid $tertiary !important;
}

.quaternary-border-color {
    border: 1px solid $quaternary !important;
}
.quinary-border-color {
    border: 1px solid $quinary !important;
}
.white-border-color {
    border-color: $white !important;
}


.primary-border {
    border: 1px solid $primary !important;
}

.quinary-border{
    border: 1px solid $quinary !important;
}

.alert-danger-border{
    border: 1px solid $alert-danger !important;
}
.alert-sucess-border {
    border: 1px solid $alert-sucess !important;
}
.gray-1-border {
    border: 1px solid $gray-1 !important;
}
.gray-2-border {
    border: 1px solid $gray-2 !important;
}
.gray-3-border {
    border: 1px solid $gray-3 !important;
}


/* .pink-1-border {
    border: 1px solid $pink-1 !important;
}
.pink-2-border {
    border: 1px solid $pink-2 !important;
}
.pink-3-border {
    border: 1px solid $pink-3 !important;
}
.pink-4-border {
    border: 1px solid $pink-4 !important;
} */


@mixin toAnim ($tempo) {
    -webkit-transition: all $tempo ease-in-out;
    -moz-transition: all $tempo ease-in-out;
    -o-transition: all $tempo ease-in-out;
    transition: all $tempo ease-in-out;
}
